
import Vue from 'vue';
import ViewContent from '@/components/ViewContent.vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { CLEAR_SERVICECENTERS_PAGING_DATA, DELETE_SERVICE_CENTER, FETCH_SERVICENETWORK_CONFIGS, FETCH_SERVICE_CENTERS, FETCH_SERVICE_CENTERS_ADVANCED_SEARCH } from '@/store/globalmodules/constants';
import { DynamicFulfillmentState } from '@/store/dynamicfulfillment/types';
import ConfigIndexComponent from '@/components/configuration/ConfigIndexComponent.vue';
import { locationManagementApiProvider } from '@/common/locationManagement.api';
import { authorizationApiProvider } from '@/common/df.authorization.api';
import {
  GlobalModuleState,
  ServiceCenterLocationConfig,
  ServiceLocationSearchCriteria,
  ServiceNetworkConfig,
  StoreHours,
} from '@/store/globalmodules/types';
import { Action, State } from 'vuex-class';
import DataTable from '@/components/common/DataTable.vue';
import EventBus from '@/common/event.bus';
import { buildErrorMessage } from '@/common/functions.helpers';
import { UserAuthorizationProvider, userAuthorizationProviderFactory } from '@/common/userAuthorization.api';
import ServiceAreaModal from '@/components/ServiceCenters/ServiceAreaModal.vue';
import { DateTime } from 'luxon';
const namespace: string = 'globalmodule';

@Component({
  components: { ViewContent, ConfigIndexComponent, DataTable, ServiceAreaModal },
})
export default class ViewServiceCenterLocations extends Vue {
  @Action(FETCH_SERVICE_CENTERS_ADVANCED_SEARCH, { namespace }) private advancedSearch: (searchCriteria: ServiceLocationSearchCriteria) => {};
  @Action(FETCH_SERVICE_CENTERS, { namespace }) private fetchServiceCenters: any;
  @Action(CLEAR_SERVICECENTERS_PAGING_DATA, { namespace }) private clearServiceCenters: any;
  @Action(DELETE_SERVICE_CENTER, { namespace }) private deleteServiceCenter: any;
  @Action(FETCH_SERVICENETWORK_CONFIGS, { namespace })  private fetchServiceNetworkConfigs: any;
  @State(namespace) private gmprofile!: GlobalModuleState;
  @State('dynamicfulfillmentmodule') private profile!: DynamicFulfillmentState;

  private title: string = 'Service Center Locations';
  private locations: ServiceCenterLocationConfig[] = null;
  private searchCriteria: ServiceLocationSearchCriteria = null;
  private initialSort: string = 'userAudit.current.modifiedOnUtc';
  private currentPage = 1;
  private pageSize = 10;
  private stickyHeaderSize = '750px';
  private totalRows = 1;
  private postalCode: string = '';
  private serviceNetwork: string = '';
  private serviceCenterName: string = '';
  private countryCodes: string = '';
  private showActiveOnly: boolean = true;
  private filterFields=['storeId','name','serviceNetwork','isActive'];
  private fields: any[] = [
    {
      key: 'storeId',
      label: 'Store Id',
      thStyle: { width: '10%' },
      sortable: true,
      filterable: true,
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '20%' },
      sortable: true,
      filterable: true,
    },
    {
      key: 'serviceNetwork',
      label: 'Service Network',
      thStyle: { width: '15%' },
      sortable: true,
      filterable: true,
    },
    {
      key: 'address',
      sortable: false,
      filterable: true,
      label: 'Address',
      thStyle: { width: '15%' },
      formatter: (value: any, key: any, item: any) => {
        return `${value.line1 || ''}<br>
          ${value.line2 ? `${value.line2}<br>` : ''}
          ${value.line3 ? `${value.line3}<br>` : ''}
          ${value.region || ''}, ${value.city || ''} ${value.postalCode || ''}<br>       
          ${value.country || ''}`;
      },
    },
    {
      key: 'isActive',
      label: 'Is Active',
      filterable: true,
    },
    {
      key: 'userAudit.current.name',
      sortable: true,
      label: 'Last Modified User',
    },
    {
      key: 'details',
      label: 'Details',
    },
    {
      key: 'userAudit.current.modifiedOnUtc',
      sortable: true,
      label: 'ModifiedOn Utc',
      formatter: (value: any, key: any, item: any) => {
        return value === '0001-01-01T00:00:00' ? '' : value;
      },      
    },
    'actions',
  ];
  private customAttributeFields = ['storeId','name','serviceNetwork','isActive'];
  private isFetchingAdditionalRows: boolean = false;
  private createRoute: string = 'ServiceCenterLocation-Add';
  private uploadRoute: string = 'ServiceCenterLocations-Upload';
  private isExporting: boolean = false;  
  private blur: string = '2px';
  private sortBy: string = 'userAudit.current.modifiedOnUtc';
  private sortDesc: boolean = true;
  private allServiceNetworkConfigs: ServiceNetworkConfig[] = [];
  private userAuthorizationProvider: UserAuthorizationProvider;

  private async mounted() {
    this.userAuthorizationProvider = userAuthorizationProviderFactory(this.profile);
    this.allServiceNetworkConfigs = await this.fetchServiceNetworkConfigs();
    this.load();
  }

  private async load() {
    this.isFetchingAdditionalRows = true;
    await this.fetchServiceCenters();
    var locs = this.gmprofile.serviceCentersPage.serviceCenters;
    if (this.isQueryParamExist()) {
      locs =  locs.filter((l) => l.serviceNetwork === this.$route.query.serviceNetwork);
    }

    if ((locs && this.locations && locs.length === this.locations.length) || locs.length===0) {
      this.isFetchingAdditionalRows = false;
      return;
    } else {
      this.locations = [...locs];
      this.formatTime();
      this.totalRows = this.locations.length;
      this.load();
    }
  }

  private async refresh() {
    this.locations = null;
    this.searchCriteria = null;
    this.clearServiceCenters();
    this.load();
  }

  private async exportServiceCenters() {
    this.isExporting = true;
    await locationManagementApiProvider.exportServiceCenters(this.searchCriteria ? this.searchCriteria : null).then( (response: any) => {
      this.isExporting = false;
    }).catch( (error) => {
      this.isExporting = false;
      EventBus.$emit('e', `<h3>Download failed.</h3>${this.formatToastMessage(error)}`);
    });
  }

  private async deleteServiceCentersCache() {
    try {
      await locationManagementApiProvider.deleteServiceCentersCache();
      this.refresh();
      EventBus.$emit('s', 'Cache has been successfully cleared.');
    } catch (error) {
      EventBus.$emit('e', `<h3>Clear cache failed.</h3>${(error)}`);
    }
  }

  private formatToastMessage(error: any) {
            if (error && error.response && error.response.data && error.response.data.errors) {
                const errors = error.response.data.errors;
                return `<div><strong>${errors[Object.keys(error.response.data.errors)[0]]}</strong><br/>`;
            }
            if (error && error.response && error.response.data && error.response.data.message) {
                return `<div><strong>${error.response.data.message}</strong><br/>`;
            }
            if (error && error.response && error.response.data) {
                return `<div><strong>${error.response.data}</strong><br/>`;
            }
            return '';
  }

  private async clearAllSearchFields() {
    this.postalCode = '';
    this.serviceNetwork = '';
    this.serviceCenterName = '';
    this.countryCodes = '';
    this.load();
  }

  private async getAdvancedSearchRecords() {
    this.searchCriteria = {
        countryCodes: this.countryCodes.split(','),
        serviceNetwork: this.serviceNetwork,
        postalCode: this.postalCode,
        serviceCenterName: this.serviceCenterName,
        showActiveOnly: this.showActiveOnly,
    };

    this.locations = await this.advancedSearch(this.searchCriteria) as ServiceCenterLocationConfig[];
    this.formatTime();
    this.totalRows = this.locations.length;
  }

  private async formatTime() {
    this.locations.forEach( (l: ServiceCenterLocationConfig) => {
      l.openHours.forEach ( (h: StoreHours) => {
        h.startTimeFormatted = DateTime.fromISO(h.startTime).toFormat('HH:mm');
        h.endTimeFormatted = DateTime.fromISO(h.endTime).toFormat('HH:mm');
      });
    });
  }

  private hasPermission(features: string[]) {
    if (!this.userAuthorizationProvider)
    {
      this.userAuthorizationProvider = userAuthorizationProviderFactory(this.profile);
    }
    return this.userAuthorizationProvider.hasPermission(features);
  }

  private async remove(item: any) {
        this.$bvModal.msgBoxConfirm('Are you sure? This cannot be undone.', {
            title: `Delete ${item.serviceNetwork}-${item.storeId}`,
            size: 'lg',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
            })
            .then( async (value) => {
                if (value) {
                    const request = {serviceNetwork: item.serviceNetwork, storeId: item.storeId}
                    this.deleteServiceCenter(request).then( async (response: any) => {
                        EventBus.$emit('s', `${request.serviceNetwork}-${request.storeId} deleted successfully`);
                        await authorizationApiProvider.deleteServiceCentralCache();
                        await authorizationApiProvider.deleteServicePowerServiceCentersCache();
                        this.refresh();
                    }).catch( (error: any) => {
                        this.displayError(error);
                    });
                }
            })
            .catch( (err) => {
                this.displayError(err);
            });
  }
  
  private displayError(error: any) {
      const message = buildErrorMessage(error);
      EventBus.$emit('e', `There was a problem in processing the request. ${message}`);
  }

  private isQueryParamExist() {
    return 'serviceNetwork' in this.$route.query ? true : false; 
  }

  @Watch('$route.query', { immediate: false })
  private loadUponChange() {
    this.load();
  }

  private get serviceAreaModalId() {
    return (location: ServiceCenterLocationConfig) => {
      return `${location.storeId}:${location.serviceNetwork}`;
    }
  }
}
