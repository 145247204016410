import { render, staticRenderFns } from "./CustomerLocationRepairFOVendorConfigCreateEdit.vue?vue&type=template&id=5f83df08&scoped=true"
import script from "./CustomerLocationRepairFOVendorConfigCreateEdit.vue?vue&type=script&lang=ts"
export * from "./CustomerLocationRepairFOVendorConfigCreateEdit.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f83df08",
  null
  
)

export default component.exports