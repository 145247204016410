
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import PerLineTextAreaControl from '@/components/common/PerLineTextAreaControl.vue';
import { camelize, distinct, GroupBy } from '@/common/functions.helpers';
import { IHasAllowedAttributes, AllowedAttribute } from '@/store/dynamicfulfillment/types';
const namespace: string = 'globalmodules';

@Component({ components: { PerLineTextAreaControl } })
export default class AttributeComponent extends Vue {
    @Prop({ required: true }) private attribute: AllowedAttribute;
    @Prop({ required: true }) private config: IHasAllowedAttributes;
    @Prop({ required: true }) private index: number;
    @Prop({ required: false, type: Function }) private deleteValidationFunc: (item: AllowedAttribute) => { isValid: boolean, message?: string };

    private ariaInvalid: boolean = false;
    private textAreaStateError: string = '';
    private dataTypes: string[] = [
        'String',
        'Boolean',
        'Integer',
        'Date',
        'Decimal',
    ];
    private allowedAttributeFields: string[] = [
        'key',
        'dataType',
        'displayName',
        'defaultValue',
        'isRequired',
        'allowMultiple',
        'description',
        'actions',
    ];
    private isClearTextArea: boolean = false;

    private isString() {
        return this.attribute.dataType === 'String';
    }

    private isBoolean() {
        return this.attribute.dataType === 'Boolean';
    }

    private isInteger() {
        return this.attribute.dataType === 'Integer';
    }

    private isDate() {
        return this.attribute.dataType === 'Date';
    }

    private isDecimal() {
        return this.attribute.dataType === 'Decimal';
    }

    private showAllowedValues(dataType: string) {
        return (
            this.isString() ||
            this.isInteger() ||
            this.isDecimal()
        );
    }

    private get keyState() {
        return !!this.attribute.key && !this.hasDuplicates();
    }

    private get dataTypeState() {
        return this.attribute.dataType ? true : false;
    }

    private get displayNameState() {
        return this.attribute.displayName ? true : false;
    }

    private get descriptionState() {
        return this.attribute.description ? true : false;
    }

    private mounted() {
        if (this.attribute.dataType === "Boolean") {
            this.attribute.defaultValue = false;
        }
    }

    private hasDuplicates() {
        let keys = this.config.allowedAttributes
            .map((x) => x.key)
            .filter((x) => x !== undefined);

        keys.splice(this.index, 1);
        return keys.some((x) => x.toLowerCase() === this.attribute.key.toLowerCase());
    }

    private hasDuplicate(): boolean {
        const duplicateAttributes = GroupBy<any, AllowedAttribute[]>(this.config.allowedAttributes, (attr: AllowedAttribute) => attr.key, (group: any[], key: string) => {
            return group;
        });
        return duplicateAttributes && duplicateAttributes.filter((duplicates: any[]) => duplicates.length > 1).length >= 1;
    }

    private emitOnChange(content: {
        config: IHasAllowedAttributes | null;
        isValid: boolean;
    }) {
        this.$emit('onConfigChange', content);
    }

    private formatter(value: string) {
        return value.replace(',', '').replace("'", '');
    }

    private updateAllowedValues(allowedValues: any[]) {
        let values: any[] = [];
        allowedValues.forEach((value: any) => {
            values.push(value);
        });
        this.attribute.allowedValues = values.filter((str: any) => str !== '');
    }

    private get textAreaState() {
        if (!this.attribute.allowedValues) return true;
        if ((this.isInteger() || this.isDecimal())) {
            if (!this.isValidNumbers || !this.isUniqueValues) {                
                return false;
            } else {
                this.setValidTextAreaState();
                return true;
            }
        } else if (!this.isUniqueValues){
            return false;
        } else {
            this.setValidTextAreaState();
            return true;
        }
    }

    private get isValidNumbers() {
        const invalidValues = this.attribute.allowedValues.filter((v: any) => isNaN(v));
        if (invalidValues.length > 0) {
            this.textAreaStateError = 'Invalid number';
            this.ariaInvalid = true;
            return false;
        }
        return true;
    }

    private get isUniqueValues() {
        const duplicateValues = this.attribute.allowedValues.filter(distinct);
        if (duplicateValues && duplicateValues.length !== this.attribute.allowedValues.length) {
            this.textAreaStateError = 'Contains duplicate values';
            this.ariaInvalid = true;
            return false;
        }
        return true;
    }

    private setValidTextAreaState() {
        this.textAreaStateError = '';
        this.ariaInvalid = null;
    }

    private removeCustomAttribute(item: AllowedAttribute) {
        const validation = this.deleteValidationFunc ? this.deleteValidationFunc(item) : { isValid: true };

        if (validation.isValid) {
            const index = this.config.allowedAttributes.indexOf(item);
            this.config.allowedAttributes.splice(index, 1);
            this.validateAttributes();
        } else {
            this.$bvModal.msgBoxOk(validation.message);
        }
    }

    private validateAttributes() {
        this.emitOnChange({
                config: this.config,
                isValid: !this.hasDuplicate() && this.keyState && this.dataTypeState && this.displayNameState && this.descriptionState && this.textAreaState,
            });
    }

    @Watch('attribute.dataType', { immediate: false })
    private onDataTypeChange() {
        this.attribute.defaultValue = null;
        this.attribute.allowedValues = null;
        this.isClearTextArea = !this.isClearTextArea;
        if (this.isBoolean || this.isDate) {
            this.attribute.allowMultiple = false;
        }
    }

    @Watch('attribute.key', { immediate: false })
    private onDisplayNameChange() {
        this.attribute.key = camelize(this.attribute.key);
    }

    @Watch('attribute', { immediate: false, deep: true})
    private attributesChanged() {
        this.validateAttributes();
    }
}
