
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { DynamicFulfillmentState, FulfillmentOption, FulfillmentTicketStatus, IPriceListConfig, PriceListConfig, DeviceTypeIdLookupStrategy, ConfigType, IConfig, IConfigHistory, RoutingAction } from '@/store/dynamicfulfillment/types';
import { FETCH_PRICE_LIST_CONFIGS, FETCH_PROGRAM_CONFIGS, GET_PRICE_LIST_CONFIGS, CLEAR_PRICE_LIST_CONFIGS, FETCH_PRICE_LIST_CONFIG, FETCH_PRICE_LIST_CONFIG_ITEMS, DELETE_PRICE_LIST_CONFIG } from '@/store/dynamicfulfillment/constants';
import ViewContent from '@/components/ViewContent.vue';
import { Action, Getter, State } from 'vuex-class';
import XLSX from 'xlsx';
import ProgramConfigModal from '../configuration/ProgramConfigModal.vue';
import { UserAuthorizationProvider, userAuthorizationProviderFactory } from '@/common/userAuthorization.api';
import FilterOnFields from '@/components/common/FilterOnFields.vue';
import { DateTime } from 'luxon';
import EventBus from '@/common/event.bus';
import PaginationPageSizeControl from '@/components/common/PaginationPageSizeControl.vue';
import { jsonToText } from '@/common/functions.helpers';
import VueJSONEditor from '@/components/VueJSONEditor.vue';
import { FETCH_CONFIG_HISTORY, UPDATE_PRICE_LIST_CONFIG } from '@/store/constants';
import ConfigHistoryModalComponent from '@/components/configuration/ConfigHistoryModalComponent.vue';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { ViewContent, ProgramConfigModal: ProgramConfigModal, FilterOnFields, PaginationPageSizeControl, VueJSONEditor, ConfigHistoryModalComponent } })
export default class ConfigIndex<T> extends Vue {
  @Prop() title: string;
  @Prop() fields: any[];
  @Prop() initialSort: string;
  @Prop() apiRoute: string;
  @Prop() route: string;
  @Prop() csvFields: any[];
  @Prop() defaultConfig: any;
  @Prop({ default: null }) mappedConfig: IPriceListConfig<T>;
  @Action(FETCH_PRICE_LIST_CONFIGS, { namespace }) fetchPriceListConfigs: (request: {route: string, forceFetch: boolean}) => Promise<any>;
  @Action(FETCH_PRICE_LIST_CONFIG_ITEMS, { namespace }) fetchPriceListConfigItems: any;
  @Action(FETCH_PROGRAM_CONFIGS, { namespace }) fetchProgramConfigs: any;
  @Getter(GET_PRICE_LIST_CONFIGS, { namespace }) getPriceListConfigs: any;
  @Action(DELETE_PRICE_LIST_CONFIG, { namespace }) deletePriceListConfig: any;
  @State(namespace) profile!: DynamicFulfillmentState;

  configs: IPriceListConfig<T>[] = null;
  configsHistory: IConfigHistory[] = null;
  configData: any = null;
  filter: string = '';
  currentPage = 1;
  pageSize = 10;
  stickyHeaderSize = '625px';
  totalRows = 1;
  sortBy = '';
  fulfillmentOptionName: string;
  ignoredFields = ['_attachments'];
  createRoute: string = null;
  isExportCsv: boolean = false;
  filterOnFields: any[] = [];
  userAuthorizationProvider: UserAuthorizationProvider;
  currentPageSize = 10;
  configHistory: IConfigHistory = {
    _etag: null,
    id: '',
    history: [],
  };  

  async created() {
    this.userAuthorizationProvider = userAuthorizationProviderFactory(this.profile);
    this.createRoute = `${this.route + 'PriceListConfig-Create'}`;
    await this.load(false);
  }

  async load(forceFetch: boolean) {
    if (this.apiRoute) {
      await this.fetchProgramConfigs();
      await this.fetchPriceListConfigs({route: this.apiRoute, forceFetch: forceFetch});
      await this.getConfigs();
      this.sortBy = this.initialSort;
    }
  }

  onFiltered(filteredItems: IPriceListConfig<T>[]) {
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  }

  get allFields() {
    const fields = [...this.fields];
    fields.push('actions');
    return fields;
  }

  get canCreate() {
    return this.hasPermission(['Configuration-PriceLists-Create']);
  }

  hasPermission(features: string[]) {
    if (!this.userAuthorizationProvider) {
      this.userAuthorizationProvider = userAuthorizationProviderFactory(this.profile);
    }
    return this.userAuthorizationProvider.hasPermission(features);
  }

  getFulfillmentOptionName(fulfillmentOption: number) {
    return FulfillmentOption[fulfillmentOption];
  }

  getFilename(data: any) {
    const fileName = data.vendor;
    let serviceProviderNames = '';
    let programNames = '';
    if (data.serviceProviders.length > 0) {
      data.serviceProviders.forEach((element: string) => {
        serviceProviderNames += `_${element}`;
      });
    }
    if (data.programIds.length > 1) {
      programNames += '_Multiple';
    } else if (data.programIds.length === 1) {
      programNames = `_${this.getProgramName(data.programIds[0])}`;
    }
    return `${fileName}${serviceProviderNames}_${this.getFulfillmentOptionName(data.fulfillmentOption)}${programNames}_${DateTime.now().toFormat('yyyyMMdd')}`;
  }

  getProgramName(programId: string) {
    const programConfig = this.profile.programConfigs.filter((program) => program.id === programId)[0];
    return programConfig ? programConfig.name : 'Unknown Program';
  }

  async getConfigs() {
    this.configs = Object.values(await this.getPriceListConfigs(this.apiRoute));
    this.totalRows = this.configs.length;
  }

  async download(data: any, isCsv: boolean) {
    this.isExportCsv = isCsv;
    this.configData = data;
    const config = await this.fetchPriceListConfigItems({ id: data.item.id, route: this.apiRoute }).catch((err: any) => {
        EventBus.$emit('e', `<h3>Request failed.</h3> ${err.response.data.message}`);
      });
    this.$emit('mapConfig', config);
  }

  async remove(config: any) {
    this.$bvModal
      .msgBoxConfirm('Are you sure? This cannot be undone.', {
        title: ``,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value) {
          await this.deletePriceListConfig(config).then(async () => {
            await this.getConfigs();
          });
          EventBus.$emit('s', `${config.id} deleted successfully`);
        }
      })
      .catch((err) => {
        // An error occurred
        EventBus.$emit('e', `Request failed. ${err}`);
      });
  }

  @Watch('mappedConfig', { immediate: false, deep: true })
  mappedConfigChange() {
    const fileName = this.getFilename(this.configData.item);
    const worksheet = XLSX.utils.json_to_sheet(this.mappedConfig.items, { header: this.csvFields });
    const newWorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(newWorkBook, worksheet, 'PriceList');
    if (this.isExportCsv) {
      XLSX.writeFile(newWorkBook, `${fileName}.csv`);
    } else {
      XLSX.writeFile(newWorkBook, `${fileName}.xlsx`);
    }
  }

  filterOnFieldsHasChanged(filterOnFields: string[]) {
    this.filterOnFields = filterOnFields;
  }

  get routeActions() {
    return (data:IPriceListConfig<T>) => [      
      { name: RoutingAction.Revert, action: UPDATE_PRICE_LIST_CONFIG, requiredPermission: 'Configuration-PriceLists-Edit', params: {apiRoute: this.apiRoute, config: data} },
    ];
  }

  @Watch('pageSize', { immediate: true })
  updatePageSize(newValue: any, oldValue: any) {
    this.pageSizeChanged(newValue);
  }

  pageSizeChanged(pageSize: any) {
    this.currentPageSize = pageSize;
  }

  @Watch('configs', {immediate: true})
  updateConfigsHistory(newValue: IConfig[], oldValue: IConfig[]) {
    this.configsHistory = JSON.parse(JSON.stringify(this.configs));
  }

  get historyModalId() {
    return (id: string) => `${id}-history`;
  }

  get historyConfigModalId() {
    return (id: string) => `${id}-history-config`;
  }

  get historyConfigModalTitle() {
    return (item: IConfig) => `${item.userAudit.current.name}-${item.userAudit.current.modifiedOnUtc}`;
  }

  showHistory(item: IConfig) {
    let historyItem = this.configsHistory.find((x) => x.id === item.id);
    if(historyItem.history) { 
      this.history = historyItem.history;
      this.$bvModal.show(this.historyModalId(item.id));
      return;
    }
    this.$store.dispatch(FETCH_CONFIG_HISTORY, {configType: ConfigType.PriceLists, id: item.id}).then((response: any) => {
      historyItem.history = response;
      this.history = response;
      this.$bvModal.show(this.historyModalId(item.id));
    });
  }

  showHistoryConfig(item: IConfig) {
    this.$bvModal.show(this.historyConfigModalId(item.id));
  }

  async hideHistory(item: IConfig) {    
    this.$bvModal.hide(this.historyModalId(item.id)); 
    await this.load(true);
  }

  set history(value: IConfig[]) {
    this.configHistory.history = value;
  }

  get history() {
    return this.configHistory.history.sort((a, b) => {
            return a.userAudit.current.modifiedOnUtc < b.userAudit.current.modifiedOnUtc ? 1 : -1;
        });
  }

  get nullBlobConfigs() {
    return this.configs.filter((config) => config.itemsBlobName === null || config.itemsBlobName === undefined); 
  }

  hasNullBlob(id: string) {
    return this.nullBlobConfigs.some((config) => config.id === id);  
  }
}
