
import { Component } from 'vue-property-decorator';
import { UpgradePriceListConfig, UpgradePriceListItem } from '@/store/dynamicfulfillment/types';
import ConfigIndex from '@/components/PriceLists/ConfigIndex.vue';
@Component({ components: { ConfigIndex } })
export default class UpgradePriceListConfigIndex extends ConfigIndex<UpgradePriceListItem> {
  private priceListsFields: any[] = [
    'id',
    {
      key: 'vendor',
      filterable: true,
    },
    {
      key: 'serviceProviders',
      filterable: true,
    },
    {
      key: 'fulfillmentOption',
      filterable: true,
    },
    'programIds',
    {
      key: 'priceListItemCount',
      label: 'Price List Item Count',
    },
    {
      key: 'userAudit.current.name',
      label: 'Last Modified User',
      sortable: true,
    },
    {
      key: 'userAudit.current.modifiedOnUtc',
      label: 'ModifiedOn Utc',
      sortable: true,
      formatter: (value: any, key: any, item: any) => {
        return value === '0001-01-01T00:00:00' ? '' : value;
      },
    },
    'actions',
  ];
  private cFields: any[] = ['claimedOracleId', 'claimedEquipmentDescription', 'migratedOracleId', 'migratedEquipmentDescription', 'vendorId', 'migrationRank', 'msrp', 'effectiveStartDate', 'effectiveEndDate'];
  private configExport: UpgradePriceListConfig = {
          fulfillmentOption: null,
          vendor: null,
          itemsBlobName: null,
          programIds: [],
          serviceProviders: [],
          documentType: null,
          items: null,
          id: null,
          _etag: null,
          lastModifiedOnUtc: null,
          lastModifiedBy: null,
          priceListItemCount: null,
          deviceTypeIdLookupStrategy: null,
          invoiceTaxStrategy: null,
      };

  private mapConfig(config: UpgradePriceListConfig) {
    this.configExport = config;
  }
}
