
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ConfigIndexComponent from '@/components/configuration/ConfigIndexComponent.vue';
import { DynamicFulfillmentState, Product, SourceSystemConfig, ProductMappingAttribute, RoutingAction, ConfigType } from '@/store/dynamicfulfillment/types';
import { Action, State } from 'vuex-class';
import { PRODUCTS_FETCH, PRODUCTS_DELETE, FETCH_CONFIG_HISTORY, PRODUCTS_SAVE } from '@/store/constants';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { ConfigIndexComponent } })
export default class ProductsIndex extends Vue {
  @Prop() private sourceSystem: string;
  @Prop() private sourceSystemConfig: SourceSystemConfig;
  @State('dynamicfulfillmentmodule') private profile!: DynamicFulfillmentState;
  config: SourceSystemConfig;
  filterOnFields: string[] = [];
  fields = [
    {
      key: 'id',
      thStyle: { width: '275px' },
      sortable: true,
      filterable: true,
    },
    {
      key: 'clientId',
      sortable: true,
      filterable: true,
    },
    {
      key: 'name',
      sortable: true,
      filterable: true,
    },
    {
      key: 'description',
      sortable: true,
      filterable: true,
    },
  ];
  configType: ConfigType = ConfigType.Products;

  get allFields() {
    const fields = [...this.fields];

    if (this.config.productMappingAttributes.length > 0) {
      fields.push(
        ...this.config.productMappingAttributes.map((x) => ({
          key: x.name,
          thStyle: null,
          sortable: true,
          filterable: true,
          ...(x.type === 'Contains' && {
            formatter: (x: ProductMappingAttribute) => {
              return x instanceof Array ? x.join(', ') : '';
            },
          }),
          ...(x.type === 'Range' && {
            formatter: (x: ProductMappingAttribute) => {
              if (typeof x === 'string') {
                return (x as string) ? (x as string).replace('_', ', ') : '';
              }
            },
          }),
        })),
      );
    }

    return fields;
  }

  beforeMount() {
    this.config = this.getSourceSystemConfig;
  }

  get getSourceSystemConfig() {
    return this.sourceSystemConfig !== undefined ? this.sourceSystemConfig : this.profile.sourceSystemConfigs.find(s=> s.id===this.sourceSystem);
  }

  get routeActions() {
    return [
      { name: RoutingAction.Edit, route: 'Products-Edit', requiredPermission: 'Configuration-Products-Edit', params: { sourceSystem: this.sourceSystem } },
      { name: RoutingAction.Create, route: 'Products-Create', requiredPermission: 'Configuration-Products-Create' },
      { name: RoutingAction.Fetch, action: PRODUCTS_FETCH, requiredPermission: 'Configuration-Products-View', params: { sourceSystem: this.sourceSystem } },
      { name: RoutingAction.Remove, action: PRODUCTS_DELETE, requiredPermission: 'Configuration-Products-Delete' },
      { name: RoutingAction.History, action: FETCH_CONFIG_HISTORY, requiredPermission: 'Configuration-Products-View' },
      { name: RoutingAction.Revert, action: PRODUCTS_SAVE, requiredPermission: 'Configuration-Products-Edit' },
    ];
  }

  filterProducts(row: any, filter: string) {
    if (!filter) {
      return true;
    }

    filter = filter.toLowerCase().trim();
    let fieldKeys = this.allFields.map((x) => x.key).filter((x) => Object.keys(row).indexOf(x) >= 0);

    if (this.filterOnFields.length == 0) {
      return fieldKeys.some((p: string) => this.filterRow(row[p], filter));
    } else {
      return fieldKeys.filter((x) => this.filterOnFields.indexOf(x) >= 0).some((p: string) => this.filterRow(row[p], filter));
    }
  }

  filterRow(propValue: any, filter: string): any {
    if (!propValue) return false;

    if (Array.isArray(propValue)) {
      return propValue.some((value: string) => value.toLowerCase().indexOf(filter.toLowerCase()) >= 0);
    } else {
      return propValue.toLowerCase().indexOf(filter) >= 0;
    }
  }

  filterOnFieldsChanged(filterOnFields: string[]) {
    this.filterOnFields = filterOnFields;
  }
}
