
  import Vue from 'vue';
  import { Component, Prop } from 'vue-property-decorator';
  import FulfillmentTicketStatusLabel from '@/components/FulfillmentAuthorization/FulfillmentTicketStatusLabel.vue';
  import ConfigIndexComponent from '@/components/configuration/ConfigIndexComponent.vue';
  import { STATUS_MAPPING_FETCH, DELETE_STATUS_MAPPING, FETCH_CONFIG_HISTORY, SAVE_STATUS_MAPPING } from '@/store/constants';
  import { ConfigType, Enums, FulfillmentOption, FulfillmentTicketStatus, RoutingAction, WorkflowDefinition, } from '@/store/dynamicfulfillment/types';
  import { DateTime } from 'luxon';
import OrderStatusLabel from '@/components/FulfillmentAuthorization/OrderStatusLabel.vue';
  
  @Component({
    components: { FulfillmentTicketStatusLabel,OrderStatusLabel, ConfigIndexComponent },
  })
  export default class StatusMappingIndex extends Vue {
    @Prop() statusType: string;
    fields: any[] = [
      {
        key: 'vendor',
        sortable: true,
      },
      {
        key: 'fulfillmentOption',
        sortable: true,
        filterable: true,
        filterByFormatted: true,
        formatter: (value: any, key: any, item: any) => {
          return FulfillmentOption[value];
        },
      },
      {
        key: 'serviceNetwork',
        sortable: true,
      },
      {
        key: 'sourceStateId',
        sortable: true,
        filterable: true,
      },
      {
        key: 'targetStatus',
        sortable: true,
        filterable: true,
        filterByFormatted: true,
        formatter: (value: any, key: any, item: any) => {
          return this.targetStatus(value);
        },
      },
      {
        key: 'targetReason',
        sortable: true,
        filterable: true,
      },
      {
        key: 'triggeringWorkflows',
        label: 'Workflow Name(s)',
        filterable: true,
        formatter: (value: any, key: any, item: any) => {
          return value.map((workflow: WorkflowDefinition) => workflow.name).join('<br />');
        },
      },
    ];
    exportFields: any[] = ['id', 'vendor', 'fulfillmentOption','serviceNetwork', 'sourceStateId', 'targetStatus', 'targetReason', 'action'];
    configExport: {
      id: string;
      vendor: string;
      fulfillmentOption: FulfillmentOption;
      serviceNetwork: string;
      sourceStateId: string;
      targetStatus: any;
      targetReason: string;
      action: string;
      triggeringWorkflows: WorkflowDefinition[];
    }[] = null;

    get title() {
        return this.statusType === 'vendorticketstatus' ? 'Vendor Ticket Status Mapping': 'Order Status Mapping';
    }
  
    get indexRoute() {
        switch(this.statusType) {
            case 'vendorticketstatus':
                return 'vendorticketstatusmapping';
            case 'orderstatus':
                return 'orderstatusmapping';
            default:
                return '';
        }
    }
  
    get routeActions() {
        switch(this.statusType) {
            case 'vendorticketstatus':
                return [
                    { name: RoutingAction.Edit, route: 'VendorTicketStatusMapping-Edit', requiredPermission: 'Configuration-VendorTicketStatusMapping-Edit', params: {statusType: 'vendorticketstatus'} },
                    { name: RoutingAction.Create, route: 'VendorTicketStatusMapping-Create', requiredPermission: 'Configuration-VendorTicketStatusMapping-Create' },
                    { name: RoutingAction.Upload, route: 'VendorTicketStatusMapping-Upload', requiredPermission: 'Configuration-VendorTicketStatusMapping-Upload', params: {apiRoute: 'vendorticketstatus' }},
                    { name: RoutingAction.Fetch, action: STATUS_MAPPING_FETCH, requiredPermission: 'Configuration-VendorTicketStatusMapping-View', params: {apiRoute: 'vendorticketstatus'}},
                    { name: RoutingAction.Remove, action: DELETE_STATUS_MAPPING, requiredPermission: 'Configuration-VendorTicketStatusMapping-Delete' , params: {apiRoute: 'vendorticketstatus'}},
                    { name: RoutingAction.Download, requiredPermission: 'Configuration-VendorTicketStatusMapping-Download' },
                    { name: RoutingAction.History, action: FETCH_CONFIG_HISTORY, requiredPermission: 'Configuration-VendorTicketStatusMapping-View' },
                    { name: RoutingAction.Revert, action: SAVE_STATUS_MAPPING, requiredPermission: 'Configuration-VendorTicketStatusMapping-Edit', params: {apiRoute: 'vendorticketstatus'} },
                ];
            case 'orderstatus':
                return [
                    { name: RoutingAction.Edit, route: 'OrderStatusMapping-Edit', requiredPermission: 'Configuration-OrderStatusMapping-Edit', params: {statusType: 'orderstatus'} },
                    { name: RoutingAction.Create, route: 'OrderStatusMapping-Create', requiredPermission: 'Configuration-OrderStatusMapping-Create' },
                    { name: RoutingAction.Upload, route: 'OrderStatusMapping-Upload', requiredPermission: 'Configuration-OrderStatusMapping-Upload', params: {apiRoute: 'orderstatus' }},
                    { name: RoutingAction.Fetch, action: STATUS_MAPPING_FETCH, requiredPermission: 'Configuration-OrderStatusMapping-View', params: {apiRoute: 'orderstatus'}},
                    { name: RoutingAction.Remove, action: DELETE_STATUS_MAPPING, requiredPermission: 'Configuration-OrderStatusMapping-Delete' , params: {apiRoute: 'orderstatus'}},
                    { name: RoutingAction.Download, requiredPermission: 'Configuration-OrderStatusMapping-Download' },
                    { name: RoutingAction.History, action: FETCH_CONFIG_HISTORY, requiredPermission: 'Configuration-OrderStatusMapping-View' },
                    { name: RoutingAction.Revert, action: SAVE_STATUS_MAPPING, requiredPermission: 'Configuration-OrderStatusMapping-Edit', params: {apiRoute: 'orderstatus'} },
                ];
            default:
                return [];
        }             
    }

    get targetStatus() {
        return (value: number) => {
            switch(this.statusType) {
                case 'vendorticketstatus':
                    return FulfillmentTicketStatus[value];
                case 'orderstatus':
                    return Enums.OrderStatus[value];
            }
        }        
    }
  
    mapConfig(configs: any[]) {
      this.configExport = configs.map((item: any) => ({
        id: item.id,
        vendor: item.vendor,
        fulfillmentOption: FulfillmentOption[item.fulfillmentOption],
        sourceStateId: item.sourceStateId,
        serviceNetwork: item.serviceNetwork,
        targetStatus: this.statusType === 'vendorticketstatus'? FulfillmentTicketStatus[item.targetStatus] : Enums.OrderStatus[item.targetStatus],
        targetReason: item.targetReason,
        action: item.action,
        triggeringWorkflows: item.triggeringWorkflows,
      })) as [];
    }
  
    get exportFileName() {
        switch(this.statusType) {
            case 'vendorticketstatus':
                return `VendorTicketStatusMapping_${DateTime.now().toFormat('yyyyMMddHHmm')}.xlsx`;
            case 'orderstatus':
                return `OrderStatusMapping_${DateTime.now().toFormat('yyyyMMddHHmm')}.xlsx`;
            default:
                return `${DateTime.now().toFormat('yyyyMMddHHmm')}.xlsx`;
        }
    }

    get configType() {
      switch(this.statusType) {
        case 'vendorticketstatus':
          return ConfigType.VendorTicketStatus;
        case 'orderstatus':
          return ConfigType.OrderStatus;
        default:
          return '';
      }
    }
  }
  