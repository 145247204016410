
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
  AppointmentConfig,
} from '@/store/dynamicfulfillment/types';

@Component({})
export default class AppointmentConfigCreateEdit extends Vue {
  @Prop({ required: true }) private appointmentConfig: AppointmentConfig;
}
