
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import {
  FulfillmentOptionConfig,
  Vendor,
  VendorConfig,
} from '@/store/dynamicfulfillment/types';
import { FETCH_VENDOR_CONFIGS } from '@/store/constants';
import { fulfillmentOptionVendorConfigFactory } from '@/common/fulfillmentOptionVendorConfigFactory';

@Component
export default class FulfillmentOptionVendorConfigListCreateEdit extends Vue {
  @Prop({ required: true }) config: FulfillmentOptionConfig;

  vendorConfigs: VendorConfig[] = null;
  existingVendorCount: number;

  async mounted() {
    this.vendorConfigs = await this.$store.dispatch(FETCH_VENDOR_CONFIGS);
    this.config.vendors.forEach(x => {
        if (x.operationsStrategy == null) {
            Vue.set(this.config.vendors.find((v: Vendor) => v.name === x.name), 'operationsStrategy', { name: null});
        }
    });
    this.existingVendorCount = this.config.vendors.length;
  }

  addVendor(config: FulfillmentOptionConfig) {
    config.vendors.push({operationsStrategy: { name: null}, canUpdateTicketAuthorizationAmount: false} as Vendor);
  }

  removeVendor(config: FulfillmentOptionConfig, item: Vendor) {
    this.remove(config.vendors, item);
  }

  remove(items: any[], item: any) {
    const index = items.indexOf(item);
    items.splice(index, 1);
  }

  getVendorBtnText(name: any) {
    return name === undefined ? "Add New Vendor" : name;
  }

  isExpanded(index: number) {
    return (index+1 > this.existingVendorCount) ? true : false;
  }

  getVendorConfigComponent() {
    return fulfillmentOptionVendorConfigFactory.GetFulfillmentOptionVendorConfigComponent(this.config.fulfillmentOption);
  } 

  get validForm() {
    return !this.config.vendors.some(function (vendor: Vendor) : boolean {
          return  vendor.requiresPriceList === null;
    });
  }

  emitOnChange(content: { config: FulfillmentOptionConfig | null; isValid: boolean }) {
    this.$emit('onConfigChange', content);
  }

  @Watch('config', { immediate: false, deep: true })
  configChange() {
    this.emitOnChange({ config: this.config, isValid: this.validForm });
  }
}
