
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import ConfigIndexComponent from '@/components/configuration/ConfigIndexComponent.vue';
import { FETCH_PROGRAM_FEES_CONFIGS, DELETE_PROGRAM_FEES_CONFIG, FETCH_PROGRAM_CONFIGS } from '@/store/dynamicfulfillment/constants';
import { RoutingAction, ConfigType, DynamicFulfillmentState, ProgramConfig } from '@/store/dynamicfulfillment/types';
import ProgramConfigModal from '@/components/configuration/ProgramConfigModal.vue';
import DataTable from '@/components/common/DataTable.vue';
const namespace: string = 'dynamicfulfillmentmodule';
@Component({ components: { ConfigIndexComponent, DataTable, ProgramConfigModal } })
export default class ProgramFeesConfigIndex extends Vue {
  @State(namespace) profile!: DynamicFulfillmentState;
  @Action(FETCH_PROGRAM_CONFIGS, { namespace }) fetchProgramConfigs: () => ProgramConfig[];
  configType: ConfigType = ConfigType.ProgramFees;
  programConfigs: ProgramConfig[] = [];
  fields: any[] = [
    {
      key: 'id',
      sortable: true,
      filterable: true,
    },
    {
      key: 'programId',
      sortable: true,
      filterable: true,
      filterByFormatted: true,
      formatter: (value: any, key: any, item: any) => {
        return this.programConfigName(value);
      },
    },
    {
      key: 'fulfillmentOption',
      label: 'Fulfillment Option',
      filterable: true,
      filterByFormatted: true,
    },
    {
      key: 'details',
    },
  ];
  feesFields: any[] = [
    {
      key: 'feeType',
    },
    {
      key: 'proposedAmount',
    },
    {
      key: 'actualAmount',
    },
  ];

  async beforeMount() {
    if (this.profile.programConfigs.length === 0) {
      await this.fetchProgramConfigs();
    }
    this.programConfigs = this.profile.programConfigs;
  }

  programConfigName(id: string) {
    const programConfig = this.programConfigs.find((config) => config.id === id);
    return programConfig ? programConfig.name : id;
  }

  get routeActions() {
    return [
      { name: RoutingAction.Edit, route: 'ProgramFeesConfig-Edit', requiredPermission: 'Configuration-ProgramFees-Edit' },
      { name: RoutingAction.Create, route: 'ProgramFeesConfig-Create', requiredPermission: 'Configuration-ProgramFees-Create' },
      { name: RoutingAction.Fetch, action: 'dynamicfulfillmentmodule/' + FETCH_PROGRAM_FEES_CONFIGS, requiredPermission: 'Configuration-ProgramFees-View' },
      { name: RoutingAction.Remove, action: 'dynamicfulfillmentmodule/' + DELETE_PROGRAM_FEES_CONFIG, requiredPermission: 'Configuration-ProgramFees-Delete' },
    ];
  }
}
