
import { copyToClipboard } from '@/common/utilities';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class JsonControl extends Vue {
    @Prop({default: 'root'}) private rootKey: string;
    @Prop({required: true}) private object: any;
    @Prop({default: 1}) private innerDepth: number;

    private handleClick() {
        copyToClipboard(JSON.stringify(this.object, null, 2));
    }
}
