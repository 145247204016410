import axios, { AxiosRequestConfig } from 'axios';
import Vue from 'vue';
import { ServiceLocationPage, ServiceLocationSearchCriteria, ServiceNetworkConfig, ServiceManufacturer, ServiceCenterLocationConfig } from '@/store/globalmodules/types';

declare var process: any;
declare var NProgress: any;

import qs from 'query-string';
import { SERVICE_CENTERS_LOCATION_PAGING_COUNT } from '@/store/globalmodules/constants';

const instance = axios.create({
  baseURL: process.env.VUE_APP_LOCATION_APIM_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

const multipartFormInstance = axios.create({
  baseURL: process.env.VUE_APP_LOCATION_APIM_BASE_URL,
  headers: {
    'Accept': '*/*',
    'Access-Control-Allow-Origin': '*',
  },
});

instance.interceptors.request.use(
    (config: any) => {
      NProgress.start();
      return config;
    },
    (error: any) => {
      NProgress.done();
      return Promise.reject(error);
    },
  );

instance.interceptors.response.use(
    (config: any) => {
      NProgress.done();
      return config;
    },
    (error: any) => {
      NProgress.done();
      return Promise.reject(error);
    },
  );

export class LocationManagementApiProvider {
  public async addServiceLocation(locationData: ServiceCenterLocationConfig) {
    await this.post('/ServiceCenters', locationData);
}

public async updateServiceLocation(locationData: ServiceCenterLocationConfig) {
  await this.put<ServiceCenterLocationConfig>(`/ServiceCenters/${locationData.storeId}`, locationData);
}

public async getServiceLocationById(id: string) {
  return await this.get<ServiceCenterLocationConfig>(`/ServiceCenters/${id}`);
}

  public async getServiceLocations(pagingToken: string) {
    if (pagingToken) {
      return await this.get<ServiceLocationPage>(`/ServiceCenters/paging?count=${SERVICE_CENTERS_LOCATION_PAGING_COUNT}&pagingToken=${pagingToken}`);
    } else {
      return await this.get<ServiceLocationPage>(`/ServiceCenters/paging?count=${SERVICE_CENTERS_LOCATION_PAGING_COUNT}`);
    }
  }

  public async exportServiceCenters(searchCriteria: ServiceLocationSearchCriteria) {
    if (searchCriteria) {
      // tslint:disable-next-line:max-line-length
      const queryString = qs.stringifyUrl({url: '/ServiceCenters/export?', query: {postalCode: searchCriteria.postalCode,
        serviceNetwork: searchCriteria.serviceNetwork,
        countryCodes: searchCriteria.countryCodes,
        serviceCenterName: searchCriteria.serviceCenterName,
        showActiveOnly: searchCriteria.showActiveOnly }},
        {skipEmptyString: true});
      return await this.getServiceCenters(queryString);
    }
    return await this.getServiceCenters('/ServiceCenters/export');
  }

  public async searchServiceLocations(searchCriteria: ServiceLocationSearchCriteria) {
      // tslint:disable-next-line:max-line-length
      const queryString = qs.stringifyUrl({
          url: '/ServiceCenters/search?', query: {
              postalCode: searchCriteria.postalCode,
          serviceNetwork: searchCriteria.serviceNetwork,
          countryCodes: searchCriteria.countryCodes,
          serviceCenterName: searchCriteria.serviceCenterName,
          showActiveOnly: searchCriteria.showActiveOnly }},
          {skipEmptyString: true});
      return await this.get<ServiceCenterLocationConfig[]>(queryString);
  }

  public async postServiceCenterLocations(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return await this.upload('/ServiceCenters/Upload', formData);
  }

  public async deleteServiceCenter(request: {serviceNetwork: string, storeId: string}){
    const response = await this.delete(`/ServiceCenters/${request.serviceNetwork}/${request.storeId}`);
    return response;
  }

  private async getServiceCenters(route: string) {
    return await this.getBaseResponseByConfig<Blob>(route).then((response) => {
      const blob = new Blob(["\uFEFF"+response.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      let fileName = 'unknown';
      const contentDisposition = response.headers['content-disposition'];
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename=(.+)/);
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1].split(';')[0];
        }
      }
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    });
  }

  public async getServiceNetworkConfigs() {
    const response = await this.get<ServiceNetworkConfig[]>('/Configuration/networkConfigs');
    return response;
  }

  public async getServiceNetworkConfig(serviceNetwork: string) {
    return await this.get<ServiceNetworkConfig>(`/Configuration/networkConfig/${serviceNetwork}`);
  }

  public async addServiceNetworkConfig(config: ServiceNetworkConfig): Promise<void> {
    await this.post<ServiceNetworkConfig>(`/Configuration/networkConfig`, config);
  }

  public async updateServiceNetworkConfig(config: ServiceNetworkConfig): Promise<void> {
    await this.put<ServiceNetworkConfig>(`/Configuration/networkConfig`, config);
  }

  public async deleteServiceCentersCache(){
    const response = await this.delete(`/ServiceCenters/deleteCache`);
    return response;
  }

  public async getServiceManufacturers() {
    return await this.get<ServiceManufacturer[]>('/ServiceManufacturer');
  }

  public async getServiceManufacturer(manufacturerId: string) {
    return await this.get<ServiceManufacturer>(`/ServiceManufacturer/${manufacturerId}`);
  }

  public async addServiceManufacturer(manufacturer: ServiceManufacturer): Promise<void> {
    await this.post<ServiceManufacturer>(`/ServiceManufacturer`, manufacturer);
  }

  public async updateServiceManufacturer(manufacturer: ServiceManufacturer): Promise<void> {
    await this.put<ServiceManufacturer>(`/ServiceManufacturer/${manufacturer.id}`, manufacturer);
  }

  public async postServiceManufacturers(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return await this.upload('/ServiceManufacturer/Upload', formData);
  }

  private async post<TReturn>(uri: string, body?: any, config?: AxiosRequestConfig) {
      config = await this.addAuthHeader(config);
      const { data } = await instance.post<TReturn>(uri, body, config);
      return data;
  }

  private async put<TReturn>(uri: string, body?: any, config?: AxiosRequestConfig) {
    config = await this.addAuthHeader(config);
    const { data } = await instance.put<TReturn>(uri, body, config);
    return data;
  }

  private async upload<TReturn>(uri: string, body?: any, config?: AxiosRequestConfig) {
    config = await this.addAuthHeader(config);
    const { data } = await multipartFormInstance.post<TReturn>(uri, body, config);
    return data;
  }

  private async get<TReturn>(uri: string, config?: AxiosRequestConfig) {
      config = await this.addAuthHeader(config);
      const response = await instance.get<TReturn>(uri, config);
      return response.data;
  }

  private async delete(uri: string, config?: AxiosRequestConfig) {
    config = await this.addAuthHeader(config);
    return await instance.delete(uri, config);
  }

  private async getBaseResponse<TReturn>(uri: string) {
    const response = await instance.get<TReturn>(uri);
    return response;
  }

  private async getBaseResponseByConfig<TReturn>(uri: string, config?: AxiosRequestConfig) {
    config = await this.addAuthHeader(config);
    const response = await instance.get<TReturn>(uri, config);
    return response;
  }
  private async addAuthHeader(config?: any) {
      config = { ...{ headers: {} }, ...config };
      const accessToken = await Vue.prototype.$auth.getAccessToken();
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
  }
}

export const locationManagementApiProvider = new LocationManagementApiProvider();
