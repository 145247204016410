
import { Component, Prop } from 'vue-property-decorator';
import { PriceListConfig, PriceListItem } from '@/store/dynamicfulfillment/types';
import ConfigItemsUpload from '@/components/PriceLists/ConfigItemsUpload.vue';
@Component({
    components: { ConfigItemsUpload },
})
export default class PriceListConfigItemsUpload extends ConfigItemsUpload<PriceListItem> {
    @Prop() private item: PriceListConfig;

    private mounted() {
        if (!this.item) {
            this.$router.push({name: 'PriceListConfig-Index'});
        }
    }
}
