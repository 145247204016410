
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import {
  FulfillmentOptionConfig,
  Vendor,
  VendorConfig,
  OfferType,
  OrchestrationMode,
  InvoiceStrategy,
} from '@/store/dynamicfulfillment/types';

@Component
export default class FulfillmentOptionVendorConfigCreateEdit extends Vue {
  @Prop({ required: true })
  fulfillmentOptionConfig: FulfillmentOptionConfig;
  @Prop({ required: true }) vendor: Vendor;
  @Prop({ required: true }) vendorConfigs: VendorConfig[];

  get allowedVendors() {
    const vendors = [] as string[];
    if (this.vendorConfigs) {
      for (const [key, value] of Object.entries(this.vendorConfigs)) {
        if (
          this.fulfillmentOptionConfig.vendors.find((x) => x.name === value.id)
        ) {
          continue;
        }
        vendors.push(value.id);
      }
    }
    return vendors;
  }

  get allowedOfferType() {
    return Object.entries(OfferType)
      .filter((e: any) => !isNaN(e[0] as any) && e[0] != 0)
      .map((e: any) => e[0]);
  }

  get allowedOrchestrationMode() {
    return Object.entries(OrchestrationMode)
      .filter((e: any) => !isNaN(e[0] as any))
      .map((e: any) => e[0]);
  }

  get allowedInvoiceStrategy() {
    return Object.keys(InvoiceStrategy);
  }

  OfferTypeLabel(option: number) {
    return OfferType[option];
  }

  OrchestrationModeLabel(option: number) {
    return OrchestrationMode[option];
  }

  getServiceProviders(item: any) {
    var sps = [] as string[];
    if (this.vendorConfigs) {
      const vendor = this.vendorConfigs.find(
        (vendorConfig: VendorConfig) => item === vendorConfig.id,
      );
      vendor?.serviceProviderConfigs.forEach((x) => {
        sps.push(x.name);
      });
    }
    return sps;
  }

  updateServiceProviders(vendor: any) {
    Vue.set(
      this.fulfillmentOptionConfig.vendors.find(
        (x: Vendor) => x.name === vendor,
      ),
      'serviceProviders',
      [],
    );
  }
}
