import { ActionTree } from 'vuex';
import {
  GlobalModuleState,
  MigrationMatrixData,
  ServiceLocationSearchCriteria,
  ServiceCenterLocationConfig,
  ServiceNetworkConfig,
  ServiceManufacturer,
} from './types';
import * as apis from './constants';
import { RootState } from '../types';
import { operationsApiProvider } from '@/common/operations.api';
import { locationManagementApiProvider } from '@/common/locationManagement.api';
import { authorizationApiProvider } from '@/common/df.authorization.api';

export const actions: ActionTree<GlobalModuleState, RootState> = {
  async [apis.POST_CLIENT_MIGRATION_MATRIX](
    context,
    requestData: { clientId: string; file: File; modifiedBy: string },
  ) {
    return await operationsApiProvider.postClientMigrationMatrix(
      requestData.clientId,
      requestData.file,
      requestData.modifiedBy,
    );
  },

  async [apis.POST_ASSURANT_MIGRATION_MATRIX](
    context,
    requestData: { clientId: string; file: File; modifiedBy: string },
  ) {
    return await operationsApiProvider.postAssurantMigrationMatrix(
      requestData.clientId,
      requestData.file,
      requestData.modifiedBy,
    );
  },

  async [apis.FETCH_MIGRATION_MATRIX_CLIENTS](context) {
    let migrationMatrixClients = context.state.migrationMatrixClients;
    if (migrationMatrixClients.length > 0) {
      return migrationMatrixClients;
    }
    migrationMatrixClients =
      await operationsApiProvider.getMigrationMatrixClients();
    context.commit(apis.SET_MIGRATION_MATRIX_CLIENTS, migrationMatrixClients);
    return migrationMatrixClients;
  },

  async [apis.FETCH_MIGRATION_MATRIX_DATA](context) {
    let migrationMatrixData = context.state.migrationMatrixPagingData;
    if (migrationMatrixData && migrationMatrixData.pagingToken) {
      const migrationMatrixResponse =
        await operationsApiProvider.getMigrationMatrixPagingData(
          migrationMatrixData.pagingToken,
        );
      migrationMatrixData.migrationMatrices.push(
        ...migrationMatrixResponse.migrationMatrices,
      );
      migrationMatrixData.pagingToken = migrationMatrixResponse.pagingToken;
    } else if (migrationMatrixData) {
      return migrationMatrixData.migrationMatrices; // reached end of the page. Do not make the api call.
    } else {
      migrationMatrixData =
        await operationsApiProvider.getMigrationMatrixPagingData(null);
    }
    context.commit(apis.SET_MIGRATION_MATRIX_PAGING_DATA, migrationMatrixData);
    return migrationMatrixData.migrationMatrices;
  },

  async [apis.FETCH_SERVICE_CENTERS](context) {
    let serviceCentersPage = context.state.serviceCentersPage;
    if (serviceCentersPage && serviceCentersPage.pagingToken) {
      const response = await locationManagementApiProvider.getServiceLocations(
        serviceCentersPage.pagingToken,
      );
      serviceCentersPage.serviceCenters.push(...response.serviceCenters);
      serviceCentersPage.pagingToken = response.pagingToken;
    } else if (
      serviceCentersPage.serviceCenters &&
      serviceCentersPage.serviceCenters.length > 0
    ) {
      return serviceCentersPage.serviceCenters; // reached end of the page. Do not make the api call.
    } else {
      serviceCentersPage =
        await locationManagementApiProvider.getServiceLocations(null);
    }
    context.commit(apis.SET_SERVICECENTERS_PAGING_DATA, serviceCentersPage);
    return serviceCentersPage.serviceCenters;
  },

  async [apis.FETCH_SERVICE_CENTERS_ADVANCED_SEARCH](
    context,
    searchCriteria: ServiceLocationSearchCriteria,
  ) {
    return await locationManagementApiProvider.searchServiceLocations(
      searchCriteria,
    );
  },

  async [apis.CLEAR_SERVICECENTERS_PAGING_DATA](context) {
    context.commit(apis.CLEAR_SERVICECENTERS_PAGING_DATA);
  },

  async [apis.ADD_SERVICECENTERS_LOCATION_DATA](
    context,
    serviceCenterLocation: ServiceCenterLocationConfig,
  ) {
    context.commit(
      apis.ADD_SERVICECENTERS_LOCATION_DATA,
      serviceCenterLocation,
    );
  },

  async [apis.EDIT_SERVICECENTERS_LOCATION_DATA](
    context,
    serviceCenterLocation: ServiceCenterLocationConfig,
  ) {
    context.commit(
      apis.EDIT_SERVICECENTERS_LOCATION_DATA,
      serviceCenterLocation,
    );
  },

  async [apis.FETCH_SERVICENETWORK_CONFIGS](context) {
    let serviceNetworkConfigs =
      await locationManagementApiProvider.getServiceNetworkConfigs();
    context.commit(apis.SET_SERVICENETWORK_CONFIGS, serviceNetworkConfigs);
    return serviceNetworkConfigs;
  },

  async [apis.FETCH_SERVICENETWORK_CONFIG](context, serviceNetwork: string) {
    return await locationManagementApiProvider.getServiceNetworkConfig(
      serviceNetwork,
    );
  },

  async [apis.SET_SERVICENETWORK_CONFIGS](
    context,
    serviceNetwork: ServiceNetworkConfig,
  ) {
    if (serviceNetwork.isEdit) {
      return await locationManagementApiProvider.updateServiceNetworkConfig(
        serviceNetwork,
      );
    } else {
      return await locationManagementApiProvider.addServiceNetworkConfig(
        serviceNetwork,
      );
    }
  },

  async [apis.DELETE_SERVICE_CENTER](
    context,
    request: { serviceNetwork: string; storeId: string },
  ) {
    await locationManagementApiProvider.deleteServiceCenter(request);
    context.commit(apis.DELETE_SERVICE_CENTER, request);
  },

  async [apis.DELETE_SERVICECENTERS_CACHE](context) {
    return await locationManagementApiProvider.deleteServiceCentersCache();
  },

  async [apis.SAVE_SERVICECENTER_LOCATION](
    context,
    config: ServiceCenterLocationConfig,
  ) {

    if (config.isEdit) {
      await locationManagementApiProvider.updateServiceLocation(
        config,
      );
      // Update view page:
      /* To update/add userAudit to state.serviceLocationData data for newly added or edited location 
        so it can be shown on the locations view page without refreshing the page. */
        var locationId = config.storeId + ":" + config.serviceNetwork;
        const serviceLocation = await locationManagementApiProvider.getServiceLocationById(locationId);
        context.commit(apis.EDIT_SERVICECENTERS_LOCATION_DATA, serviceLocation);
      } else {
        await locationManagementApiProvider.addServiceLocation(config);
        var locationId = config.storeId + ":" + config.serviceNetwork;
        const serviceLocation = await locationManagementApiProvider.getServiceLocationById(locationId);
        context.commit(apis.ADD_SERVICECENTERS_LOCATION_DATA, serviceLocation);
      }
      // Clear service central and service power cache, which include service centers:
      await authorizationApiProvider.deleteServiceCentralCache();
      await authorizationApiProvider.deleteServicePowerServiceCentersCache();
      return config;
    },

    async [apis.FETCH_SERVICE_MANUFACTURERS](context) {
      let serviceManufacturers = context.state.serviceManufacturers;
      if (serviceManufacturers.length > 0) {
        return serviceManufacturers;
      }
      serviceManufacturers = await locationManagementApiProvider.getServiceManufacturers();
      context.commit(apis.SET_SERVICE_MANUFACTURERS, serviceManufacturers);
      return serviceManufacturers;
    },

    async [apis.FETCH_SERVICE_MANUFACTURER](context, manufacturerId: string) {
      return await locationManagementApiProvider.getServiceManufacturer(manufacturerId);
    },

    async [apis.UPDATE_SERVICE_MANUFACTURER](
      context,
      serviceManufacturer: ServiceManufacturer,
    ) {
      await locationManagementApiProvider.updateServiceManufacturer(
        serviceManufacturer,
      );
      context.commit(apis.RESET_SERVICE_MANUFACTURERS);
      await context.dispatch(apis.FETCH_SERVICE_MANUFACTURERS);
    },
  
    async [apis.SAVE_SERVICE_MANUFACTURER](
      context,
      serviceManufacturer: ServiceManufacturer,
    ) {
      if (
        serviceManufacturer.eTag == null &&
        context.state.serviceManufacturers.find(
          (c) => c.id === serviceManufacturer.id,
        )
      ) {
        throw Error(
          `Service Manufacturer with Id ${serviceManufacturer.id} already exists.`,
        );
      }
      await locationManagementApiProvider.addServiceManufacturer(serviceManufacturer);
      await authorizationApiProvider.clearCache();
      context.commit(apis.RESET_SERVICE_MANUFACTURERS);
      await context.dispatch(apis.FETCH_SERVICE_MANUFACTURERS);
    },
};
