
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {  DynamicFulfillmentState, UpgradePriceListItem } from '@/store/dynamicfulfillment/types';
import ConfigItemsEdit from '@/components/PriceLists/ConfigItemsEdit.vue';
import { Action, State } from 'vuex-class';
import { FETCH_PRICE_LIST_CONFIG_ITEMS } from '@/store/dynamicfulfillment/constants';
const namespace: string = 'dynamicfulfillmentmodule';
@Component({ components: { ConfigItemsEdit } })
export default class UpgradePriceListConfigItemsEdit extends Vue {
  @Prop() private id!: string;
  private items!: UpgradePriceListItem[];
  @Action(FETCH_PRICE_LIST_CONFIG_ITEMS, { namespace }) private fetchPriceListConfigItems: (request: {id: string, route: string}) => Promise<any>;
  @State(namespace) private profile!: DynamicFulfillmentState;

  private async mounted() {
    await this.fetchPriceListConfigItems({id: this.id, route: 'upgradepricelists'});
    this.items = this.profile.upgradePriceListConfigs[this.id].items;
  }

  private get upgradePriceListItems() {
    return this.items;
  }
}
