import { GetterTree } from 'vuex';
import {
    FulfillmentOptionConfig,
    FulfillmentOption,
    DynamicFulfillmentState,
    InventoryState,
} from './types';
import * as apis from './constants';
import { RootState } from '../types';

export const getters: GetterTree<DynamicFulfillmentState, RootState> = {
    [apis.GET_SOURCE_SYSTEM_CONFIGS](state) {
        return state.sourceSystemConfigs;
    },
    [apis.GET_SOURCE_SYSTEM_CONFIG](state) {
        return (sourceSystem: string) =>
        state.sourceSystemConfigs.find((c) => c.id === sourceSystem);
    },
    [apis.GET_PRICE_LIST_CONFIGS]: (state) => (route: string): { [key: string]: any; } => {
        switch (route) {
            case 'upgradepricelists' : {
                return state.upgradePriceListConfigs;
            }
            default : {
                return state.priceListConfigs;
            }
        }
    },
    [apis.GET_PRICE_LIST_CONFIG]: (state) => (route: string): any => {
        switch (route) {
            case 'upgradepricelists' : {
                return (id: string) =>
                    state.upgradePriceListConfigs[id];
            }
            default : {
                return (id: string) =>
                state.priceListConfigs[id];
            }
        }
    },
    [apis.GET_FULL_PRICE_LIST_CONFIGS]: (state) => (route: string): { [key: string]: any; } => {
        switch (route) {
            case 'upgradepricelists' : {
                return state.fullUpgradePriceListConfigs;
            }
            default : {
                return state.fullPriceListConfigs;
            }
        }
    },
    [apis.GET_FULFILLMENT_OPTION_CONFIG]: (state) => (fulfillmentOption: FulfillmentOption): FulfillmentOptionConfig => {
        return state.fulfillmentOptionConfigs.find((c) => c.id === FulfillmentOption[fulfillmentOption]);
    },

    [apis.GET_INVENTORY_STATE]: (state) => (id: string): any => {
        return state.inventoryStates.find( (inventory: InventoryState) => inventory.id === id);
    },
    [apis.GET_USER_ROLES_PERMISSIONS](state) {
        return state.userRolesPermissions;
    },
};
