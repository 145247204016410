import { MutationTree } from 'vuex';
import {  GlobalModuleState, MigrationMatrixDataResponse, ServiceLocationPage, ServiceNetworkConfig, ServiceManufacturer, ServiceCenterLocationConfig } from './types';
import * as apis from './constants';
import { MigrationMatrixSettingData, MigrationMatrixData } from '@/store/globalmodules/types';

export const mutations: MutationTree<GlobalModuleState> = {
    [apis.SET_MIGRATION_MATRIX_CLIENTS](state, migrationMatrixClients: MigrationMatrixSettingData[]) {
        state.migrationMatrixClients = migrationMatrixClients;
    },
    [apis.SET_MIGRATION_MATRIX_PAGING_DATA](state, migrationMatrixData: MigrationMatrixDataResponse) {
        state.migrationMatrixPagingData = migrationMatrixData;
    },
    [apis.SET_MIGRATION_MATRIX_DATA](state, migrationMatrixData: MigrationMatrixData[]) {
        state.migrationMatrixData = migrationMatrixData;
    },
    [apis.SET_SERVICECENTERS_PAGING_DATA](state, serviceCentersPage: ServiceLocationPage) {
        state.serviceCentersPage = serviceCentersPage;
    },
    [apis.CLEAR_SERVICECENTERS_PAGING_DATA](state) {
        state.serviceCentersPage.serviceCenters = null;
        state.serviceCentersPage.pagingToken = null;
    },
    [apis.ADD_SERVICECENTERS_LOCATION_DATA](state, serviceCenterLocation: ServiceCenterLocationConfig) {
        if(state.serviceCentersPage.serviceCenters) {
            state.serviceCentersPage.serviceCenters = state.serviceCentersPage.serviceCenters.concat(serviceCenterLocation);
        }        
    },
    [apis.EDIT_SERVICECENTERS_LOCATION_DATA](state, serviceCenterLocation: ServiceCenterLocationConfig) {
        if(state.serviceCentersPage.serviceCenters){
            const index = state.serviceCentersPage.serviceCenters.findIndex( (s: ServiceCenterLocationConfig) => s.storeId === serviceCenterLocation.storeId && s.serviceNetwork === serviceCenterLocation.serviceNetwork );   
            state.serviceCentersPage.serviceCenters.splice(index, 1, serviceCenterLocation);
        }  
    },
    [apis.DELETE_SERVICE_CENTER](state, request: {serviceNetwork: string, storeId: string}) {
        const index = state.serviceCentersPage.serviceCenters.findIndex( (sc: ServiceCenterLocationConfig) => sc.serviceNetwork === request.serviceNetwork && sc.storeId === request.storeId);
        state.serviceCentersPage.serviceCenters.splice(index, 1);
    },
    [apis.SET_SERVICENETWORK_CONFIGS](state, serviceNetworkConfigs: ServiceNetworkConfig[]) {
        state.serviceNetworkConfigs = serviceNetworkConfigs;
    },
    [apis.SET_SERVICE_MANUFACTURERS](state, serviceManufacturers: ServiceManufacturer[]) {
        state.serviceManufacturers = serviceManufacturers;
    },
    [apis.RESET_SERVICE_MANUFACTURERS](state) {
        state.serviceManufacturers = [];
    },
};
