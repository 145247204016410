
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { copyToClipboard, Guid } from '@/common/utilities';
import {
  FulfillmentOption,
  FulfillmentTicketStatus,
  PriceListConfig,
  InsertPriceListItemsRequest,
  PriceListItem,
  IPriceListConfig,
  DynamicFulfillmentState,
  InvoiceTaxStrategy,
} from '@/store/dynamicfulfillment/types';
import {
  INSERT_PRICE_LIST_ITEMS,
  FETCH_PRICE_LIST_CONFIG,
} from '@/store/dynamicfulfillment/constants';
import ViewContent from '@/components/ViewContent.vue';
import EventBus from '@/common/event.bus';
import { Action, State } from 'vuex-class';
import JsonControl from '../common/JsonControl.vue';
import { DateTime } from 'luxon';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { ViewContent, JsonControl } })
export default class ConfigItemsEdit<T> extends Vue {
  @State(namespace) private profile!: DynamicFulfillmentState;
  @Prop() private itemId!: string;
  @Prop() private configItems!: any;
  @Prop() private documentType: string;
  @Prop() private apiConfigRoute: string;
  @Prop() private apiItemRoute: string;
  @Prop() private route: string;
  @Action(INSERT_PRICE_LIST_ITEMS, { namespace }) private insertPriceListItems: any;
  @Action(FETCH_PRICE_LIST_CONFIG, { namespace }) private fetchPriceListConfig: any;
  private saving: boolean = false;
  private priceListConfig: IPriceListConfig<T> = null;
  private priceListItemText: string = null;
  private storedItems: T[] = [];

  constructor() {
    super();
    this.priceListConfig = {
        fulfillmentOption: null,
        vendor: null,
        itemsBlobName: null,
        programIds: [],
        serviceProviders: null,
        documentType: this.documentType,
        items: null,
        id: null,
        _etag: null,
        invoiceTaxStrategy: InvoiceTaxStrategy.IncludeTaxOnInvoice,
        lastModifiedOnUtc: null,
        lastModifiedBy: null,
        priceListItemCount: null,
        deviceTypeIdLookupStrategy: null,
    };
  }

  public get pageTitle(): string {
    return this.priceListConfig
    // tslint:disable-next-line:max-line-length
      ? this.priceListConfig.vendor + '|' + FulfillmentOption[this.priceListConfig.fulfillmentOption]
      : '';
  }

  /**
   * save
   */
  public async save(e: any) {
    e.preventDefault();
    if (!this.priceListItemText) { return; }
    this.saving = true;
    const newItems: T[] = JSON.parse(this.priceListItemText);
    const insertRequest = {
      priceListConfigId: this.priceListConfig.id,
      fileName: `${this.priceListConfig.id}_${DateTime.now().toFormat('yyyyMMddHHmmss')}.json`,
      items: newItems,
      lastModifiedBy: this.profile.userClaims.name,
    };

    await this.insertPriceListItems({ payload: insertRequest, configRoute: this.apiConfigRoute, itemRoute: this.apiItemRoute}).then(() => {
      EventBus.$emit('s', 'Price List Items inserted successfully.');
      this.saving = false;
      this.$router.push({ name: `${this.route + 'PriceListConfig-Index'}` });
    }).catch((error: any) => {
      const message = (error.response === undefined) ? error : this.formatToastMessage(error.response.data.errors);
      this.saving = false;
      EventBus.$emit('e', message);
    });
  }

  private formatToastMessage(errors: any) {
    let message = '';
    const keys = Object.keys(errors);
    keys.forEach((key) => {
      message += `<div class="validation-errors"><strong>${key}</strong><br/>
      <ul>${this.formatPropertyErrorMessage(errors[key])}</ul></div>`;
    });
    return message;
  }

  private formatPropertyErrorMessage(errors: string[]) {
    let message = '';
    errors.forEach((key: string) => {
      message += `<li>${key}</li>`;
    });
    return message;
  }

  private async created() {
    if (!this.itemId && !this.apiConfigRoute) {
      return;
    }
    this.priceListConfig = await this.fetchPriceListConfig( {id: this.itemId, route: this.apiConfigRoute });
  }

  private data() {
    return {
      priceListItemText: '',
    };
  }

  private copyPayload(payload: T[]) {
    copyToClipboard(JSON.stringify(payload, null, 2));
  }
}
