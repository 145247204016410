export const GET_MIGRATION_MATRIX_CLIENTS = 'getMigrationMatrixClients';
export const FETCH_MIGRATION_MATRIX_CLIENTS = 'fetchMigrationMatrixClients';
export const SET_MIGRATION_MATRIX_CLIENTS = 'setMigrationMatrixClients';
export const POST_CLIENT_MIGRATION_MATRIX = 'postClientMigrationMatrix';
export const POST_ASSURANT_MIGRATION_MATRIX = 'postAssurantMigrationMatrix';
export const FETCH_SERVICE_CENTERS = 'fetchServiceCenters';
export const FETCH_SERVICE_CENTERS_ADVANCED_SEARCH = 'fetchServiceCentersAdvancedSearch';
export const SET_SERVICECENTERS_PAGING_DATA = 'setServiceCentersPagingData';
export const CLEAR_SERVICECENTERS_PAGING_DATA = 'clearServiceCentersPagingData';
export const ADD_SERVICECENTERS_LOCATION_DATA = 'addServiceCentersLocationData';
export const EDIT_SERVICECENTERS_LOCATION_DATA = 'editServiceCentersLocationData';
export const FETCH_MIGRATION_MATRIX_DATA = 'fetchMigrationMatrixData';
export const SET_MIGRATION_MATRIX_DATA = 'setMigrationMatrixData';
export const SET_MIGRATION_MATRIX_PAGING_DATA = 'setMigrationMatrixPagingData';
export const MIGRATION_MATRIX_PAGING_COUNT = '5000';
export const SERVICE_CENTERS_LOCATION_PAGING_COUNT = '5000';
export const DELETE_SERVICE_CENTER = 'deleteServiceCenter';
export const DELETE_SERVICECENTERS_CACHE = 'deleteServiceCentersCache';
export const FETCH_SERVICENETWORK_CONFIGS = 'fetchServiceNetworkConfigs';
export const FETCH_SERVICENETWORK_CONFIG = "fetchServiceNetworkConfig";
export const SET_SERVICENETWORK_CONFIGS = "setServiceNetworkConfigs";
export const SAVE_SERVICECENTER_LOCATION = 'saveServiceCenterLocation';
export const SAVE_SERVICE_MANUFACTURER = 'saveServiceManufacturer';
export const UPDATE_SERVICE_MANUFACTURER = 'updateServiceManufacturer';
export const RESET_SERVICE_MANUFACTURERS = 'resetServiceManufacturers';
export const FETCH_SERVICE_MANUFACTURERS = 'fetchServiceManufacturers';
export const FETCH_SERVICE_MANUFACTURER = 'fetchServiceManufacturer';
export const SET_SERVICE_MANUFACTURERS = 'setServiceManufacturers';