
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
  FulfillmentOptionConfig,
  VendorConfig,
  RepairFulfillmentOptionVendorConfig,
} from '@/store/dynamicfulfillment/types';
import { default as FulfillmentOptionVendorConfig } from '@/components/configuration/FulfillmentOptionVendorConfigs/FulfillmentOptionVendorConfigCreateEdit.vue';
import { default as AppointmentConfig } from '@/components/configuration/AppointmentConfigCreateEdit.vue';

@Component({ components: { FulfillmentOptionVendorConfig, AppointmentConfig } })
export default class RepairFOVendorConfigCreateEdit extends Vue {
  @Prop({ required: true })
  private fulfillmentOptionConfig: FulfillmentOptionConfig;
  @Prop({ required: true })
  private vendor: RepairFulfillmentOptionVendorConfig;
  @Prop({ required: true }) private vendorConfigs: VendorConfig[];

  public async mounted() {
    this.fulfillmentOptionConfig.vendors.forEach(x => {
        if (this.vendor.appointmentConfig == null) {
            Vue.set(this.fulfillmentOptionConfig.vendors.find((v: any) => v.name === x.name), 'appointmentConfig', { daysToLookOut: 0});
        }
    });
  }
}
