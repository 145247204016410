import { RouteConfig } from 'vue-router';
import PriceListConfigIndex from '@/views/configuration/PriceLists/PriceListConfigIndex.vue';
import PriceListConfigItemsEdit from '@/views/configuration/PriceLists/PriceListConfigItemsEdit.vue';
import PriceListConfigCreateEdit from '@/views/configuration/PriceLists/PriceListConfigCreateEdit.vue';
import PriceListConfigItemsUpload from '@/views/configuration/PriceLists/PriceListConfigItemsUpload.vue';
import UpgradePriceListConfigIndex from '@/views/configuration/PriceLists/UpgradePriceListConfigIndex.vue';
import UpgradePriceListConfigItemsEdit from '@/views/configuration/PriceLists/UpgradePriceListConfigItemsEdit.vue';
import UpgradePriceListConfigCreateEdit from '@/views/configuration/PriceLists/UpgradePriceListConfigCreateEdit.vue';
import UpgradePriceListConfigItemsUpload from '@/views/configuration/PriceLists/UpgradePriceListConfigItemsUpload.vue';
const routes = [
  {
    path: 'price-lists',
    name: 'PriceListConfig-Index',
    meta: {
      title: 'Price Lists',
      requiresAuth: true,
      authorizedPermissions: ['Configuration-PriceLists-View'],
    },
    component: PriceListConfigIndex,
  },
  {
    path: '/price-lists/:id/items/edit',
    name: 'PriceListConfig-Items-Edit',
    props: true,
    meta: {
      title: 'Price List Items Edit',
      requiresAuth: true,
      authorizedPermissions: ['Configuration-PriceLists-EditItems'],
    },
    component: PriceListConfigItemsEdit,
  },
  {
    path: 'price-lists/items/upload',
    name: 'PriceListConfig-Items-Upload',
    props: true,
    meta: {
      title: 'Price Lists Items Upload',
      requiresAuth: true,
      authorizedPermissions: ['Configuration-PriceLists-UploadItems'],
    },
    component: PriceListConfigItemsUpload,
  },
  {
    path: '/price-lists/:id/edit',
    name: 'PriceListConfig-Edit',
    props: true,
    meta: {
      title: 'Price List Config Edit',
      requiresAuth: true,
      authorizedPermissions: ['Configuration-PriceLists-Edit'],
    },
    component: PriceListConfigCreateEdit,
  },
  {
    path: 'price-lists/create',
    name: 'PriceListConfig-Create',
    props: true,
    meta: {
      title: 'Price List Config Create',
      requiresAuth: true,
      authorizedPermissions: ['Configuration-PriceLists-Create'],
    },
    component: PriceListConfigCreateEdit,
  },
  {
    path: 'upgrade-price-lists',
    name: 'UpgradePriceListConfig-Index',
    meta: {
      title: 'Upgrade Price List Configs',
      requiresAuth: true,
      authorizedPermissions: ['Configuration-UpgradePriceLists-View'],
    },
    component: UpgradePriceListConfigIndex,
  },
  {
    path: 'upgrade-price-lists/:id/items/edit',
    name: 'UpgradePriceListConfig-Items-Edit',
    meta: {
      title: 'Upgrade Price List Items Edit',
      requiresAuth: true,
      authorizedPermissions: ['Configuration-UpgradePriceLists-EditItems'],
    },
    props: true,
    component: UpgradePriceListConfigItemsEdit,
  },
  {
    path: 'upgrade-price-lists/:id/items/upload',
    name: 'UpgradePriceListConfig-Items-Upload',
    meta: {
      title: 'Upgrade Price List Items Upload',
      requiresAuth: true,
      authorizedPermissions: ['Configuration-UpgradePriceLists-UploadItems'],
    },
    props: true,
    component: UpgradePriceListConfigItemsUpload,
  },
  {
    path: 'upgrade-price-lists/:id/edit',
    name: 'UpgradePriceListConfig-Edit',
    meta: {
      title: 'Upgrade Price List Config Edit',
      requiresAuth: true,
      authorizedPermissions: ['Configuration-UpgradePriceLists-Edit'],
    },
    props: true,
    component: UpgradePriceListConfigCreateEdit,
  },
  {
    path: 'upgrade-price-lists/create',
    name: 'UpgradePriceListConfig-Create',
    meta: {
      title: 'Upgrade Price List Create',
      requiresAuth: true,
      authorizedPermissions: ['Configuration-UpgradePriceLists-Create'],
    },
    props: true,
    component: UpgradePriceListConfigCreateEdit,
  },
] as RouteConfig[];

export default routes;
