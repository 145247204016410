
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ConfigIndexComponent from '@/components/configuration/ConfigIndexComponent.vue';
import { FETCH_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPES_CONFIGS } from '@/store/dynamicfulfillment/constants';
import { RoutingAction, FulfillmentOption } from '@/store/dynamicfulfillment/types';
import  AttributeViewComponent from '@/components/common/AttributeViewComponent.vue';

@Component({ components: { ConfigIndexComponent, AttributeViewComponent } })
export default class ServiceNetworkFulfillmentOptionTypeConfigIndex extends Vue {
  initialSort: string = 'userAudit.current.modifiedOnUtc';
  sortDesc: boolean = true;
  attributesTitle: string = 'Attributes';
  fields: any[] = [
    {
      key: 'fulfillmentOption',
      label: 'Fulfillment Option',
      formatter: (value: any, key: any, item: any) => {
        return FulfillmentOption[value];
      },
      filterable: true,
      filterByFormatted: true,
    },
    {
      key: 'allowedAttributes',
    },
  ];

  get routeActions() {
    return [
      { name: RoutingAction.Edit, route: 'ServiceNetworkFulfillmentOptionTypeConfig-Edit', requiredPermission: 'Configuration-ServiceNetworkFulfillmentOptionType-Edit' },
      { name: RoutingAction.Create, route: 'ServiceNetworkFulfillmentOptionTypeConfig-Create', requiredPermission: 'Configuration-ServiceNetworkFulfillmentOptionType-Create' },
      { name: RoutingAction.Fetch, action: 'dynamicfulfillmentmodule/' + FETCH_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPES_CONFIGS, requiredPermission: 'Configuration-ServiceNetworkFulfillmentOptionType-View' },
    ];
  }

}

