
import { Component, Vue, Prop } from 'vue-property-decorator';
import ViewContent from '@/components/ViewContent.vue';
import EventBus from '@/common/event.bus';
import UploadComponent from '@/components/common/UploadComponent.vue';
import { DynamicFulfillmentState, FulfillmentOption, IPriceListConfig, IPriceListItem, PriceListConfig, PriceListItem } from '@/store/dynamicfulfillment/types';
import { Action, State } from 'vuex-class';
import { INSERT_PRICE_LIST_ITEMS } from '@/store/dynamicfulfillment/constants';
import { Guid } from '@/common/utilities';
import csv from 'csvtojson';
import XLSX from 'xlsx';
import { buildErrorMessage } from '@/common/functions.helpers';
const namespace: string = 'dynamicfulfillmentmodule';
@Component({
    components: { ViewContent, UploadComponent },
})
export default class ConfigItemsUpload<T> extends Vue {
    @Prop() private config: IPriceListConfig<T>;
    @Prop() private documentType: string;
    @Prop() private itemRoute: string;
    @Prop() private configRoute: string;
    @Prop() private route: string;
    @Action(INSERT_PRICE_LIST_ITEMS, { namespace }) private insertPriceListItems: any;
    @State(namespace) private profile!: DynamicFulfillmentState;
    private resetForm: boolean = false;
    private isValidState: boolean = true;
    private isValidFileType: boolean = true;
    public get pageTitle(): string {
        return this.config
            ? this.config.vendor  +  ((this.config.serviceProviders && this.config.serviceProviders.length > 0) ? ' / ' + this.config.serviceProviders.join(', ') + ' / ' : ' / ') + FulfillmentOption[this.config.fulfillmentOption]
            : '';
    }
    private validation(file: File) {
        if (file) {
            this.isValidFileType = file.name.split('.').pop() === 'csv' || file.name.split('.').pop() === 'xlsx';
        }
        return  this.isValidFileType && this.isValidState;
    }

    private formatFileName(files: File[]) {
        const fileName = files[0].name;
        return fileName.length > 50 ? [fileName.substring(0, 50), '...'].join() : fileName;
    }

    private invalidFeedBack() {
        if ( this.isValidFileType === false ) {
            return 'Invalid file. Select ".csv or .xlsx" file';
        }
        if ( this.isValidState === false) {
            return 'File contains invalid data.';
        }
    }

    private async upload(eventData: any) {
        const reader = new FileReader();
        if (eventData.file.name.split('.').pop() === 'xlsx') {
            reader.onload = ((file) => {
                return async ( e: any ) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, {type: 'array'});
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const jsonArray = XLSX.utils.sheet_to_json(worksheet);
                    this.uploadItems(jsonArray);
                };
            })(eventData.file);
            reader.readAsArrayBuffer(eventData.file);
        } else {
        // Closure to capture the file information.
            reader.onload = ((file) => {
                return async ( e: any ) => {
                    const jsonArray = await csv().fromString(e.target.result);
                    this.uploadItems(jsonArray);
                };
            })(eventData.file);
            // Read in the image file as a data URL.
            reader.readAsText(eventData.file);
        }
    }

    private async uploadItems(data: any) {
        const newItems: IPriceListItem[] = data;
        const insertRequest = {
            priceListConfigId: this.config.id,
            fileName: this.config.itemsBlobName ? this.config.itemsBlobName : `${Guid.MakeNew().ToString()}.json`,
            items: newItems,
            lastModifiedBy: this.profile.userClaims.name,
            lastModifiedOnUtc: new Date().toJSON(),
        };
        this.config.itemsBlobName = insertRequest.fileName;
        this.config.lastModifiedBy = insertRequest.lastModifiedBy;
        this.config.lastModifiedOnUtc = insertRequest.lastModifiedOnUtc;

        await this.insertPriceListItems({ payload: insertRequest, configRoute: this.configRoute, itemRoute: this.itemRoute})
            .then( () => {
                EventBus.$emit('s', 'Price List Items inserted successfully.');
                this.$router.push({ name: `${this.route + 'PriceListConfig-Index'}` });
            }).catch((error: any) => {
                this.isValidState = false;
                const message = buildErrorMessage(error);
                EventBus.$emit('e', message);
            });
    }

    private reset() {
        this.isValidState = true;
        this.isValidFileType = true;
    }
}
