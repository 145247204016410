
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import FilterOnFields from '@/components/common/FilterOnFields.vue';
import PaginationPageSizeControl from '@/components/common/PaginationPageSizeControl.vue';

@Component({ components: { FilterOnFields, PaginationPageSizeControl } })
export default class DataTable extends Vue {
  @Prop({default: false}) private small: boolean;
  @Prop() private items: any[];
  @Prop() private title: string;
  @Prop() private fields: any[];
  @Prop() private initialSort: string;
  @Prop({default: false}) private sortDesc: boolean;
  @Prop() private filterFields: any[];
  @Prop({default: 10}) private pageSize: number;
  @Prop({default: '625px'}) private stickyHeaderSize: string;
  @Prop({default: true, type: Boolean}) private showEmpty: boolean;
  @Prop({default: true, type: Boolean}) private showFilter: boolean;
  @Prop({default: false, type: Boolean}) private isFetchingAdditionalRows: boolean;
  private filter: string = null;
  private currentPage = 1;
  private totalRows = 1;
  private sortBy: string = '';
  private filterOnFields: any[] = [];
  private currentPageSize = 10;

  mounted() {
    this.currentPageSize = this.pageSize;
  }

  @Watch('items', {immediate: true})
  private onItemsChange(newValue: any[], oldValue: any[]) {
      this.totalRows = !!newValue ? newValue.length : 0;
      this.sortBy = this.initialSort;
  }

  private onFiltered(filteredItems: any[]) {
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  }

  get filterValue() {
      return this.filter;
  }

  get maxPageSize() {
    if (this.items) {
      return this.items.length;
    }
  }
  
  get showGrid() {
    return this.showEmpty ? true: this.items.length > 0;
  }

  private filterOnFieldsHasChanged(filterOnFields: string[]) {
    this.filterOnFields = filterOnFields;
  }

  pageToFirst() : void {
    this.currentPage = 1;
  }

  @Watch('pageSize', { immediate: true })
  private updatePageSize(newValue: any, oldValue: any) {
    this.pageSizeChanged(newValue);
  }

  private pageSizeChanged(pageSize: any) {
    this.currentPageSize = pageSize;
  }
}
