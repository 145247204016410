import { RouteConfig } from 'vue-router';
import VendorsIndex from '@/views/configuration/Vendors/VendorsIndex.vue';
import ServiceCentralNetworkManagementIndex from '@/views/configuration/Vendors/ServiceCentral/ServiceCentralNetworkManagementIndex.vue';
import ServiceCentralNetworkManagementEdit from '@/views/configuration/Vendors/ServiceCentral/ServiceCentralNetworkManagementEdit.vue';
import ServiceCentralNetworkManagementCreate from '@/views/configuration/Vendors/ServiceCentral/ServiceCentralNetworkManagementCreate.vue';
import ServicePowerBrandMappingIndex from '@/views/configuration/Vendors/ServicePower/ServicePowerBrandMappingIndex.vue';
import ServicePowerBrandMappingCreate from '@/views/configuration/Vendors/ServicePower/ServicePowerBrandMappingCreate.vue';
import ServicePowerBrandMappingEdit from '@/views/configuration/Vendors/ServicePower/ServicePowerBrandMappingEdit.vue';

const routes = [
  {
    path: '/vendors',
    component: VendorsIndex,
    children: [
      {
        path: 'servicecentral',
        component: VendorsIndex,
        meta: {
          displayName: 'ServiceCentral',
          authorizedPermissions: ['Vendors-ServiceCentral-NetworkManagement-View',],
        },
        children: [
          {
            path: 'serviceCentralNetworkManagement',
            name: 'ServiceCentralNetworkManagement-Index',
            component: ServiceCentralNetworkManagementIndex,
            meta: {
              title: 'ServiceCentral Network Management',
              displayName: 'Network Management',
              requiresAuth: true,
              authorizedPermissions: ['Vendors-ServiceCentral-NetworkManagement-View'],
            },
          },
          {
            path: 'serviceCentralNetworkManagementCreate',
            name: 'ServiceCentralNetworkManagement-Create',
            component: ServiceCentralNetworkManagementCreate,
            props: true,
            meta: {
              title: 'ServiceCentral Network Management Create',
              displayName: 'Network Management Create',
              topNavDisplay: false,
              requiresAuth: true,
              validateRoleMembership: true,
              authorizedPermissions: ['Vendors-ServiceCentral-NetworkManagement-Create'],
            },
          },
          {
            path: 'serviceCentralNetworkManagementEdit',
            name: 'ServiceCentralNetworkManagement-Edit',
            component: ServiceCentralNetworkManagementEdit,
            props: true,
            meta: {
              title: 'ServiceCentral Network Management Edit',
              displayName: 'Network Management Edit',
              topNavDisplay: false,
              requiresAuth: true,
              authorizedPermissions: ['Vendors-ServiceCentral-NetworkManagement-Edit'],
            },
          },
        ],
      },
      {
        path: 'servicepower',
        component: VendorsIndex,
        meta: {
          displayName: 'ServicePower',
          authorizedPermissions: ['Vendors-ServicePower-BrandMappings-View'],
        },
        children: [
          {
            path: 'servicePowerBrandMapping',
            name: 'ServicePowerBrandMapping-Index',
            component: ServicePowerBrandMappingIndex,
            meta: {
              title: 'ServicePowerBrandMapping',
              displayName: 'ServicePower Brand Mapping',
              requiresAuth: true,
              authorizedPermissions: ['Vendors-ServicePower-BrandMappings-View'],
            },
          },
          {
            path: 'servicePowerBrandMapping/create',
            name: 'ServicePowerBrandMapping-Create',
            props: true,
            component: ServicePowerBrandMappingCreate,
            meta: {
              title: 'ServicePowerBrandMapping Create',
              displayName: 'Service Power Brand Mapping Create',
              topNavDisplay: false,
              requiresAuth: true,
              authorizedPermissions: ['Vendors-ServicePower-BrandMappings-Create'],
            },
          },
          {
            path: 'servicePowerBrandMapping/edit',
            name: 'ServicePowerBrandMapping-Edit',
            props: true,
            component: ServicePowerBrandMappingEdit,
            meta: {
              title: 'ServicePowerBrandMapping Edit',
              displayName: 'Service Power Brand Mapping Edit',
              topNavDisplay: false,
              requiresAuth: true,
              authorizedPermissions: ['Vendors-ServicePower-BrandMappings-Edit'],
            },
          },
        ],
      },
    ],
  },
] as RouteConfig[];

export default routes;
