
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ViewContent from '@/components/ViewContent.vue';
import EventBus from '@/common/event.bus';
import {
  DynamicFulfillmentState,
  FulfillmentOption,
  FulfillmentOptionConfig,
  IPriceListConfig,
  IPriceListItem,
  PriceListConfig,
  InvoiceTaxStrategy,
  DeviceTypeIdLookupStrategy,
  ProgramConfig,
  Vendor,
  VendorConfig,
} from '@/store/dynamicfulfillment/types';
import {
  FETCH_PRICE_LIST_CONFIG,
  FETCH_PRICE_LIST_CONFIGS,
  FETCH_PROGRAM_CONFIGS,
  FETCH_VENDOR_CONFIGS,
  INSERT_PRICE_LIST_CONFIG,
  UPDATE_PRICE_LIST_CONFIG,
  GET_FULL_PRICE_LIST_CONFIGS,
  FETCH_FULFILLMENT_OPTION_CONFIGS,
  INSERT_PRICE_LIST_ITEMS,
  CLEAR_PRICE_LIST_CONFIGS,
} from '@/store/dynamicfulfillment/constants';
import { Action, Getter, State } from 'vuex-class';
import csv from 'csvtojson';
import XLSX from 'xlsx';
import { buildErrorMessage } from '@/common/functions.helpers';
const namespace: string = 'dynamicfulfillmentmodule';
import PerLineTextAreaControl from '@/components/common/PerLineTextAreaControl.vue';
import { DateTime } from 'luxon';

@Component({ components: { ViewContent, PerLineTextAreaControl } })
export default class ConfigCreateEdit<T> extends Vue {
  @Prop({ required: true }) private priceListConfig: IPriceListConfig<T>;
  @Prop() private documentType: string;
  @Prop() private apiRoute: string;
  @Prop() private route: string;
  @Prop() private itemRoute: string;
  @Action(FETCH_PRICE_LIST_CONFIG, { namespace })
  private fetchPriceListConfig: any;
  @Action(FETCH_PRICE_LIST_CONFIGS, { namespace })
  private fetchPriceListConfigs: any;
  @Action(FETCH_VENDOR_CONFIGS, { namespace }) private fetchVendorConfigs: any;
  @Action(FETCH_FULFILLMENT_OPTION_CONFIGS, { namespace })
  private fetchFulfillmentOptionConfigs: any;
  @Action(FETCH_PROGRAM_CONFIGS, { namespace })
  private fetchProgramConfigs: any;
  @Action(INSERT_PRICE_LIST_CONFIG, { namespace })
  private insertPriceListConfig: any;
  @Action(UPDATE_PRICE_LIST_CONFIG, { namespace })
  private updatePriceListConfig: any;
  @Action(INSERT_PRICE_LIST_ITEMS, { namespace })
  private insertPriceListItems: any;
  @Action(CLEAR_PRICE_LIST_CONFIGS, { namespace })
  private clearPriceListConfigs: (route: string) => Promise<any>;
  @Getter(GET_FULL_PRICE_LIST_CONFIGS, { namespace })
  private getFullPriceListConfigs: any;
  @State(namespace) private profile!: DynamicFulfillmentState;

  private priceListConfigs: IPriceListConfig<T>[] = [];
  private programConfigs: ProgramConfig[] = [];
  private vendorConfigs: VendorConfig[] = [];
  private selectedVendor: string = null;
  private selectedFulfillmentOption: FulfillmentOption = null;
  private fulfillmentOptionConfigs: FulfillmentOptionConfig[] = [];
  private vendorConfigNames: string[] = [];
  private selectedDeviceTypeIdLookupStrategy: DeviceTypeIdLookupStrategy = DeviceTypeIdLookupStrategy.Sku;
  private selectedInvoiceTaxStrategy: InvoiceTaxStrategy = InvoiceTaxStrategy.IncludeTaxOnInvoice;
  private file: File = null;
  private isValidState: boolean = true;
  private isValidFileType: boolean = true;
  private eventData: any = {};
  private isLoading: boolean = false;

  private get isNew() {
    return this.priceListConfig !== null && this.priceListConfig._etag === null;
  }

  private get pageTitle(): string {
    return this.priceListConfig && !this.isNew
      ? `${this.priceListConfigName}`
      : 'Create Price List Config';
  }

  private get priceListConfigName(): string {
    return this.priceListConfig
      ? `${this.priceListConfig.vendor} | ${
          FulfillmentOption[this.priceListConfig.fulfillmentOption]
        }`
      : '';
  }

  private get allowedFulfillmentOptions() {
    return Object.keys(FulfillmentOption)
      .filter((value) => isNaN(Number(value)) === false)
      .map((key: any) => FulfillmentOption[key])
      .sort((a, b) => {
        return a < b ? -1 : 1;
      });
  }

  private get allowedDeviceTypeIdLookupStrategy() {
    return Object.keys(DeviceTypeIdLookupStrategy);
  }

  private get allowedinvoiceTaxStrategy() {
    return Object.keys(InvoiceTaxStrategy);
  }

  private get fulfillmentOptionState() {
    return (
      this.selectedFulfillmentOption != null &&
      Number.parseInt(
        this.selectedFulfillmentOption.valueOf().toString(),
        10,
      ) !== Number.parseInt(FulfillmentOption.None.valueOf().toString(), 10)
    );
  }

  private get deviceTypeIdLookupStrategyState() {
    return (
      this.selectedDeviceTypeIdLookupStrategy != null &&
      this.selectedDeviceTypeIdLookupStrategy.length > 0
    );
  }

  private get invoiceTaxStrategyState() {
    return this.selectedInvoiceTaxStrategy != null;
  }

  private get vendorState() {
    return this.selectedVendor !== null;
  }

  private get priceListConfigState() {
    return this.checkFormValidity();
  }

  private get configState() {
    return (
      this.fulfillmentOptionState &&
      this.vendorState &&
      this.priceListConfigState &&
      this.deviceTypeIdLookupStrategyState &&
      this.invoiceTaxStrategyState &&
      this.validation
    );
  }

  private get invalidPriceListConfigFeedback() {
    if (this.selectedFulfillmentOption && this.selectedVendor) {
      return `There is already a price list configured with the provided options`;
    }
  }

  public async handleSubmit() {
    if (!this.checkFormValidity()) {
      return;
    }

    const requestObject = {
      id: this.priceListConfig.id,
      fulfillmentOption: FulfillmentOption[this.selectedFulfillmentOption],
      vendor: this.selectedVendor,
      itemsBlobName: this.priceListConfig.itemsBlobName
        ? this.priceListConfig.itemsBlobName
        : (null as any),
      programIds: this.priceListConfig.programIds,
      serviceProviders:
        this.priceListConfig.serviceProviders &&
        this.priceListConfig.serviceProviders.length >= 1
          ? this.priceListConfig.serviceProviders
          : [],
      deviceTypeIdLookupStrategy: this
        .selectedDeviceTypeIdLookupStrategy as unknown as DeviceTypeIdLookupStrategy,
      invoiceTaxStrategy: this
        .selectedInvoiceTaxStrategy as unknown as InvoiceTaxStrategy,
      documentType: this.documentType,
      lastModifiedBy: this.profile.userClaims.name,
      lastModifiedOnUtc: new Date().toJSON(),
      userAudit: this.priceListConfig.userAudit,
    };

    this.priceListConfig.fulfillmentOption = this.selectedFulfillmentOption;
    this.priceListConfig.vendor = requestObject.vendor;
    this.priceListConfig.itemsBlobName = requestObject.itemsBlobName;
    this.priceListConfig.serviceProviders = requestObject.serviceProviders;
    this.priceListConfig.deviceTypeIdLookupStrategy =
      DeviceTypeIdLookupStrategy[
        requestObject.deviceTypeIdLookupStrategy
      ] as unknown as DeviceTypeIdLookupStrategy;
    this.priceListConfig.invoiceTaxStrategy = requestObject.invoiceTaxStrategy;
    this.priceListConfig.documentType = requestObject.documentType;
    this.priceListConfig.lastModifiedBy = requestObject.lastModifiedBy;
    this.priceListConfig.lastModifiedOnUtc = requestObject.lastModifiedOnUtc;

    try {
      this.isLoading = true;
      if (this.isNew) {
        await this.insertPriceListConfig({
          payload: requestObject,
          route: this.apiRoute,
        }).then(async (response: any) => {
          this.priceListConfig = response;
          this.eventData = {
            file: this.file,
          };
          await this.upload(this.eventData);
        });
      } else {
        await this.updatePriceListConfig({
          config: requestObject,
          apiRoute: this.apiRoute,
        });
        this.isLoading = false;
        this.$router.push({ name: `${this.route + 'PriceListConfig-Index'}` });
      }
      EventBus.$emit('s', 'Price List Config saved successfully.');
    } catch (error) {
      // catch errors
    }
  }

  public async mounted() {
    await this.fetchProgramConfigs();
    await this.fetchVendorConfigs();
    await this.fetchFulfillmentOptionConfigs();
    this.programConfigs = this.profile.programConfigs;
    this.vendorConfigs = this.profile.vendorConfigs;
    this.fulfillmentOptionConfigs = this.profile.fulfillmentOptionConfigs;
    await this.fetchPriceListConfigs({route: this.apiRoute, forceFetch: false});
    this.priceListConfigs = this.getFullPriceListConfigs(this.apiRoute);
    if (!this.priceListConfig) {
      return;
    }
    this.selectedFulfillmentOption = this.priceListConfig.fulfillmentOption;
    this.selectedVendor = this.priceListConfig.vendor;
    this.selectedDeviceTypeIdLookupStrategy =
      DeviceTypeIdLookupStrategy[
        this.priceListConfig.deviceTypeIdLookupStrategy
      ];
    this.selectedInvoiceTaxStrategy = this.priceListConfig.invoiceTaxStrategy;
    const fulfillmentOptionConfig =
      this.profile.fulfillmentOptionConfigs.filter(
        (v: FulfillmentOptionConfig) =>
          v.fulfillmentOption === this.priceListConfig.fulfillmentOption,
      )[0];
    if (fulfillmentOptionConfig && fulfillmentOptionConfig.vendors) {
      fulfillmentOptionConfig.vendors.forEach((v: Vendor) =>
        this.vendorConfigNames.push(v.name),
      );
    }
  }

  private programLabel(programId: string) {
    return this.programConfigs.filter((program) => program.id === programId)[0]
      .name;
  }

  private fulfillmentOptionLabel(option: any) {
    if (isNaN(option)) {
      return option;
    } else {
      return FulfillmentOption[option];
    }
  }

  private priceListDeviceTypeIdLookupStrategyLabel(option: any) {
    if (isNaN(option)) {
      return option;
    } else {
      return option;
    }
  }

  private vendorLabel(option: string) {
    return this.vendorConfigs.filter((vendor) => vendor.id === option)[0].id;
  }

  private formatter(value: string) {
    return value.replace(',', '').replace("'", '');
  }

  private updateServiceProviders(updatedConfig: any) {
    this.priceListConfig.serviceProviders = updatedConfig;
  }

  @Watch('selectedFulfillmentOption', { immediate: false })
  private fulfillmentOptionChanged() {
    if (!this.isNew) {
      return;
    } // If this is edit then return, since the vendor drop down control is dsabled in edit screen.
    this.selectedVendor = null;
    this.vendorConfigNames = [];
    const fulfillmentOptionConfig = this.fulfillmentOptionConfigs.filter(
      (v: FulfillmentOptionConfig) =>
        FulfillmentOption[v.fulfillmentOption] ===
        this.selectedFulfillmentOption.toString(),
    )[0];
    if (fulfillmentOptionConfig && fulfillmentOptionConfig.vendors) {
      fulfillmentOptionConfig.vendors.forEach((v: Vendor) =>
        this.vendorConfigNames.push(v.name),
      );
    }
  }

  private checkFormValidity(): boolean {
    if (this.fulfillmentOptionState && this.vendorState) {
      let existingPriceListConfigs: IPriceListConfig<T>[] = null;
      if (this.isNew) {
        existingPriceListConfigs = this.priceListConfigs.filter(
          (plc) =>
            Number.parseInt(plc.fulfillmentOption.valueOf().toString(), 10) ===
              Number.parseInt(
                FulfillmentOption[this.selectedFulfillmentOption]
                  .valueOf()
                  .toString(),
                10,
              ) &&
            plc.vendor === this.selectedVendor &&
            (this.arraysEqual(
              plc.programIds,
              this.priceListConfig.programIds,
            ) ||
              plc.programIds.filter((programId) =>
                this.priceListConfig.programIds.includes(programId),
              ).length >= 1) &&
            (this.arraysEqual(
              plc.serviceProviders,
              this.priceListConfig.serviceProviders,
            ) ||
              plc.serviceProviders.filter((provider) =>
                this.priceListConfig.serviceProviders.includes(provider),
              ).length >= 1),
        );
      } else {
        existingPriceListConfigs = this.priceListConfigs.filter(
          (plc) =>
            Number.parseInt(plc.fulfillmentOption.valueOf().toString(), 10) ===
              Number.parseInt(
                this.selectedFulfillmentOption.valueOf().toString(),
                10,
              ) &&
            plc.vendor === this.selectedVendor &&
            plc.id !== this.priceListConfig.id &&
            (this.arraysEqual(
              plc.programIds,
              this.priceListConfig.programIds,
            ) ||
              plc.programIds.filter((programId) =>
                this.priceListConfig.programIds.includes(programId),
              ).length >= 1) &&
            (this.arraysEqual(
              plc.serviceProviders,
              this.priceListConfig.serviceProviders,
            ) ||
              plc.serviceProviders.filter((provider) =>
                this.priceListConfig.serviceProviders.includes(provider),
              ).length >= 1),
        );
      }
      return existingPriceListConfigs.length === 0;
    }
    return true;
  }

  get validation() {
    if (this.file) {
      this.isValidFileType =
        this.file.name.split('.').pop() === 'csv' ||
        this.file.name.split('.').pop() === 'xlsx';
    }
    return this.isValidFileType && this.isValidState;
  }

  private formatFileName(files: File[]) {
    const fileName = files[0].name;
    return fileName.length > 50
      ? [fileName.substring(0, 50), '...'].join()
      : fileName;
  }

  private async fileChangeEvent(files: File[]) {
    this.isValidState = true;
    this.isValidFileType = true;
  }

  private get invalidFileUploadFeedBack() {
    if (this.isValidFileType === false) {
      return 'Invalid file. Select ".csv or .xlsx" file';
    }
    if (this.isValidState === false) {
      return 'File contains invalid data.';
    }
  }
  private async upload(eventData: any) {
    EventBus.$emit('i', 'Please wait. Uploading Price List Items...');
    const reader = new FileReader();
    if (eventData.file.name.split('.').pop() === 'xlsx') {
      reader.onload = ((file) => {
        return async (e: any) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonArray = XLSX.utils.sheet_to_json(worksheet);
          await this.uploadItems(jsonArray);
        };
      })(eventData.file);
      reader.readAsArrayBuffer(eventData.file);
    } else {
      // Closure to capture the file information.
      reader.onload = ((file) => {
        return async (e: any) => {
          const jsonArray = await csv().fromString(e.target.result);
          this.uploadItems(jsonArray);
        };
      })(eventData.file);
      // Read in the image file as a data URL.
      reader.readAsText(eventData.file);
    }
  }

  private async uploadItems(data: any) {
    const newItems: IPriceListItem[] = data;
    const insertRequest = {
      priceListConfigId: this.priceListConfig.id,
      fileName: `${this.priceListConfig.id}_${DateTime.now().toFormat('yyyyMMddHHmmss')}.json`,
      items: newItems,
      lastModifiedBy: this.profile.userClaims.name,
      lastModifiedOnUtc: new Date().toJSON(),
    };

    const configRequestObject = {
      id: this.priceListConfig.id,
      fulfillmentOption: this.selectedFulfillmentOption,
      vendor: this.selectedVendor,
      itemsBlobName: insertRequest.fileName,
      programIds: this.priceListConfig.programIds,
      serviceProviders: this.priceListConfig.serviceProviders,
      deviceTypeIdLookupStrategy: this.priceListConfig.deviceTypeIdLookupStrategy,
      invoiceTaxStrategy: this.priceListConfig.invoiceTaxStrategy,
      documentType: this.documentType,
      lastModifiedBy: this.profile.userClaims.name,
      lastModifiedOnUtc: new Date().toJSON(),
    };
    await this.insertPriceListItems({
      payload: insertRequest,
      configRoute: this.apiRoute,
      itemRoute: this.itemRoute,
    })
      .then(async () => {
        await this.updatePriceListConfig({
          payload: configRequestObject,
          route: this.apiRoute,
        })
          .then(async () => {
            EventBus.$emit('s', 'Price List Items inserted successfully.');
            await this.clearPriceListConfigs(this.apiRoute);
            this.$router.push({
              name: `${this.route + 'PriceListConfig-Index'}`,
            });
          })
          .catch((error: any) => {
            EventBus.$emit('e', error);
          });
      })
      .catch((error: any) => {
        this.isValidState = false;
        const message = buildErrorMessage(error);
        EventBus.$emit('e', message);
      });
    this.isLoading = false;
  }

  private routeToFileUpload() {
    const fileUpload: any = {
      name: `${this.route + 'PriceListConfig-Items-Upload'}`,
      params: {
        item: this.priceListConfig,
      },
    };
    this.$router.push(fileUpload);
  }

  private arraysEqual(a: any[], b: any[]) {
    if (a === b) {
      return true;
    }
    if (a == null || b == null) {
      return false;
    }
    if (a.length !== b.length) {
      return false;
    }

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) {
        return false;
      }
    }
    return true;
  }
}
