import { deleteCookie } from './cookie-typescript-utils';
import decamelize from 'decamelize';

export const copyToClipboard = (text: string) => {
  const el = document.createElement('textarea');
  el.value = text.trim();
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  el.focus();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export function clearOktaState(tokenStorageKey: string) {
  sessionStorage.removeItem(tokenStorageKey);
  localStorage.removeItem(tokenStorageKey);
  localStorage.removeItem('okta-cache-storage');
  localStorage.removeItem('okta-pkce-storage');
  deleteCookie('okta-oauth-nonce');
  deleteCookie('okta-oauth-state');
}

export class Guid {
  public static MakeNew(): Guid {
    let result: string;
    let i: string;
    let j: number;

    result = '';
    for (j = 0; j < 32; j++) {
      if (j === 8 || j === 12 || j === 16 || j === 20) {
        result = result + '-';
      }
      i = Math.floor(Math.random() * 16)
        .toString(16)
        .toUpperCase();
      result = result + i;
    }
    return new Guid(result);
  }

  private guid: string;
  constructor(public guidString: string) {
    this.guid = guidString;
  }

  public ToString(): string {
    return this.guid.toLowerCase();
  }
}

export function replaceEmptyArraysWithEmptyString(obj: any): any {
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      if (obj[key].length === 0) {
        obj[key] = '';
      }
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      obj[key] = replaceEmptyArraysWithEmptyString(obj[key]);
    }
  }

  return obj;
}

export function humanizeString(foreignString: string) {
  if (typeof foreignString !== 'string') {
    throw new TypeError('Expected a string');
  }

  foreignString = decamelize(foreignString);
  let strings = foreignString
    .toLowerCase()
    .replace(/[_-]+/g, ' ')
    .replace(/\s{2,}/g, ' ')
    .trim()
    .split(' ');
  strings.forEach(function (part, index, strings) {
    strings[index] =
      strings[index].charAt(0).toUpperCase() + strings[index].slice(1);
  });
  foreignString = strings.join(' ');

  return foreignString;
}

export function extractContent(html: string) {
  return new DOMParser().parseFromString(html, 'text/html').documentElement
    .textContent;
}

export function validateJson(data: any) {
  if (data === null) {
    return false;
  }
  let value: any;
  let valueStr: string = typeof data !== "string" ? JSON.stringify(data) : data;    
  try {
    value = JSON.parse(valueStr);
  } catch (e) {
    return false;
  }
  if (typeof value === "object" && value !== null) {
    for (let key in value) {
      if (typeof key === 'string' && key.trim() !== '' && typeof value![key as any] === 'string' && value![key as any].trim() !== '') {
        return true;
      }
    }
  }
  return false;
}

export function isNullOrEmpty(value: string) {
  return value === null || value === undefined || value.trim() === '';
}
