export enum Widget {
    RecentAuthorizations,
    AuthorizationRequestsChart,
    AppInsightsExceptions,
}

export class WidgetsConfigSettings {

    private widgetsConfig = {
        layout: [
            { x: 0, y: 0, w: 7, h: 7, i: '0' },
            { x: 7, y: 0, w: 5, h: 7, i: '1' },
            { x: 8, y: 0, w: 12, h: 7, i: '2' },
        ],
        widgets: [
            {
                uid: 1,
                title: 'Recent Authorizations',
                slot: '0',
                component: Widget.RecentAuthorizations,
                componentName: 'recent-authorizations',
                allowedRoles: 'Dashboard-Widgets-RecentAuthorizations',
                isAllowedForCurrentUser: false,
                shortName: 'RecentAuth',
            },
            {
                uid: 2,
                title: 'Successful Fulfillment Authorizations',
                slot: '1',
                component: Widget.AuthorizationRequestsChart,
                componentName: 'fulfillment-auth-chart',
                allowedRoles: 'Dashboard-Widgets-SuccessfulAuthorizations',
                isAllowedForCurrentUser: false,
                shortName: 'RecentAuthChart',
            },
            {
                uid: 3,
                title: 'Errors',
                slot: '2',
                component: Widget.AppInsightsExceptions,
                componentName: 'app-insights-exceptions',
                componentProp: 'refreshAiExceptions',
                allowedRoles: 'Dashboard-Widgets-ApplicationErrors',
                isAllowedForCurrentUser: false,
                shortName: 'Errors',
            },
        ],
    };

    public isAuthorizedToViewWidget(userPermissions: string[]): WidgetsConfig {
        const result: WidgetsConfig = { layout: [], widgets: [] };
        const widgetsConfig = this.getConfig();
        const defaultWidgetsLayoutGridCount = widgetsConfig.layout.length;
        widgetsConfig.widgets.forEach((widgetItem) => {
            const widgetAllowedRoles = widgetItem.allowedRoles.split(',');
            for (const [key, value] of Object.entries(widgetAllowedRoles)) {
                userPermissions.forEach((userRole) => {
                    if (userRole.toString().endsWith(value)) {
                        widgetItem.isAllowedForCurrentUser = true;
                    }
                });
            }
            if (widgetItem.isAllowedForCurrentUser === true) {
                result.widgets.push(widgetItem);
            }
        });
        result.layout = widgetsConfig.layout;

        if (result.widgets.length < defaultWidgetsLayoutGridCount) {
            const differFromAllWidgetCount = defaultWidgetsLayoutGridCount - result.widgets.length;
            const totalUserWidgetsCount = result.widgets.length;
            for (const [key, value] of Object.entries(result.widgets)) {
                value.slot = key;
            }
            result.layout.splice(totalUserWidgetsCount, differFromAllWidgetCount);
        }
        return result;
    }
    public getConfig(): WidgetsConfig {
        return this.widgetsConfig;
    }
}

export const widgetsConfigSettings = new WidgetsConfigSettings();

export interface WidgetsConfig {
    layout: any[];
    widgets: WidgetContent[];
}

export interface WidgetContent {
    uid: number;
    title: string;
    slot: string;
    component: Widget;
    componentName: string;
    allowedRoles: string;
    isAllowedForCurrentUser: boolean;
    shortName: string;
}
