var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('config-index-component',{attrs:{"title":"Equipment Exclusion Lists","routeActions":_vm.routeActions,"fields":_vm.fields,"initialSort":"name","configType":_vm.configType},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(){return [_c('li',{staticClass:"breadcrumb-item"},[_vm._v("Configuration")]),_c('li',{staticClass:"breadcrumb-item"},[_vm._v("Equipment Exclusion Lists")])]},proxy:true},{key:"cell(id)",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell(programIds)",fn:function(row){return _vm._l((row.value),function(name,index){return _c('div',{key:name},[(row.item.programName !== 'Program Not Found')?_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-center",modifiers:{"modal-center":true}},{name:"b-modal",rawName:"v-b-modal.modal-lg",modifiers:{"modal-lg":true}}],staticClass:"align-left",attrs:{"variant":"link"},on:{"click":function($event){return _vm.$bvModal.show(row.item.id + index)}}},[_vm._v(_vm._s(name))]):_vm._e(),_c('b-modal',{attrs:{"id":row.item.id + index,"title":name,"size":"xl","ok-only":"","center":"","scrollable":""}},[_c('ProgramConfigModal',{attrs:{"id":row.item.programIds[index]}})],1)],1)})}},{key:"customActionLinks",fn:function(slotProps){return [_vm._v("   "),_c('router-link',{attrs:{"to":{
        name: 'EquipmentExclusionItems-Index',
        params: { id: slotProps.item.id },
      },"title":"View Exclusion Items"}},[_c('font-awesome-icon',{attrs:{"icon":"eye","size":"lg"}})],1),_vm._v("   "),(_vm.hasPermission(['Configuration-EquipmentExclusion-DownloadItems']))?_c('b-button',{staticClass:"pl-1 pr-0",attrs:{"variant":"link","title":"Download Exclusion Items"},on:{"click":function($event){return _vm.download(slotProps.item.id, slotProps.item.name)}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-file-excel","size":"lg"}})],1):_vm._e(),_vm._v("   "),(_vm.hasPermission(['Configuration-EquipmentExclusion-UploadItems']))?_c('router-link',{attrs:{"to":{
        name: 'EquipmentExclusionItems-Upload',
        params: { configId: slotProps.item.id },
      },"title":"Upload Exclusion Items"}},[_c('font-awesome-icon',{attrs:{"icon":"fa-upload","size":"lg"}})],1):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }