import { AxiosError } from "axios";

/**
 * Builds an error message based on the given error object.
 * If the error object does not have a `response` property, it returns the error object itself.
 * If the error object has a `response` property, it checks `data` property and displays the corresponding error messages.
 * If the error object does not have a `data` property, it returns the response object.
 */
export const buildErrorMessage = (error: AxiosError): any => {
  return !error.response ? error :
       error.response.data ? displayError(error.response.data) :
       error.response;
}

export const displayErrors = (errors: any) => {
  let message = '';
  const keys = Object.keys(errors);
  keys.forEach((key) => {
    message += `<div class="validation-errors"><strong>${key}</strong><br/>
      <ul>${formatErrorMessages(errors[key])}</ul></div>`;
  });
  return message;
};

export const displayError = (error: any) => {
  if (error.errors) {
    return displayErrors(error.errors);
  }
  return `<div class="validation-errors"><strong>${error && error.message ? error.message : error}</strong>`;
};

export const jsonToText = (data: any) => {
  return { text: JSON.stringify(data, null, 2) };
};

const formatErrorMessages = (errors: any) => {
  if (errors instanceof Array && errors.length > 0) {
    let message = '';
    errors.forEach((key: string) => {
      message += `<li>${key}</li>`;
    });
    return message;
  }

  return `<li>${errors}</li>`;
};

export const GroupBy = <T, E>(array: T[], f: any, map: (group: T[], key: string) => E) => {
  const groups = array.reduce((r: any, v: any, i: any, a: any, k: any = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
  return Object.keys(groups).map((group) => {
    return map(groups[group], group);
  });
};

export const camelize = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const distinct = (value: any, index: any, self: any) => {
  return self.indexOf(value) === index;
};

export const sort = <Type extends string | number>(arr: Type[], sortByAsc: boolean = true): Type[] => {
  return sortByAsc ? arr.sort() : arr.sort().reverse();
};

export const sortByProperty = <Type extends { [key: string]: any }>(arr: Type[], propertyName: string, sortByAsc: boolean = true): Type[] => {
  return arr.sort((a, b) => {
    if (typeof a === 'string' || typeof b === 'string') {
      return 0;
    }

    const propA = a[propertyName];
    const propB = b[propertyName];

    if (typeof propA === 'number' && typeof propB === 'number') {
      return sortByAsc ? propA - propB : propB - propA;
    }

    if (typeof propA === 'string' && typeof propB === 'string') {
      return sortByAsc ? propA.localeCompare(propB) : propB.localeCompare(propA);
    }

    // If the types are different, consider strings as greater than numbers
    return typeof propA === 'string' ? (sortByAsc ? 1 : -1) : sortByAsc ? -1 : 1;
  });
};


/**
 * Removes Cosmos system properties (properties begining with '_') from the given object.
 */
export function removeCosmosSystemProperties(obj: any): any {
  const sanitizedObj = { ...obj };
  for (const prop in sanitizedObj) {
    if (prop.startsWith('_')) {
      delete sanitizedObj[prop];
    }
  }
  return sanitizedObj;
};