
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import ConfigCreateEdit from '@/components/PriceLists/ConfigCreateEdit.vue';
import { PriceListConfig, PriceListItem } from '@/store/dynamicfulfillment/types';
import { FETCH_PRICE_LIST_CONFIG } from '@/store/dynamicfulfillment/constants';
const namespace: string = 'dynamicfulfillmentmodule';


@Component({ components: { ConfigCreateEdit } })
export default class PriceListConfigCreateEdit extends Vue {
    @Prop() private config: PriceListConfig;
    @Prop() private id!: string;
    @Action(FETCH_PRICE_LIST_CONFIG, { namespace }) private fetchPriceListConfig: any;

    private pricelists: string = "pricelists";
    private priceListConfig: PriceListConfig = null;

    public async mounted() {
        this.priceListConfig = this.config ?? await this.fetchPriceListConfig( {id: this.id, route: this.pricelists });
    }
}
