
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ViewContent from '@/components/ViewContent.vue';
import {
  FulfillmentAuthorization,
  FulfillmentOption,
  FulfillmentOptionConfig,
  SourceSystemIdentifier,
  SourceSystemConfig,
  DynamicFulfillmentState,
  DamageType,
  UserTier,
} from '@/store/dynamicfulfillment/types';
import FulfillmentAuthorizationTraces from '@/components/FulfillmentAuthorization/FulfillmentAuthorizationTraces.vue';
import FulfillmentAuthorizationRequestHistory from '@/components/FulfillmentAuthorization/FulfillmentAuthorizationRequestHistory.vue';
import SourceSystemClaimDataHistory from '@/components/FulfillmentAuthorization/SourceSystemClaimDataHistory.vue';
import PhoneNumber from '@/components/common/PhoneNumber.vue';
import FulfillmentAuthorizationStatusLabel from '@/components/FulfillmentAuthorization/FulfillmentAuthorizationStatusLabel.vue';
import FulfillmentTicketStatus from '@/components/FulfillmentAuthorization/FulfillmentTicketStatusLabel.vue';
import Communications from '@/components/FulfillmentAuthorization/Communications.vue';
import { AuthorizationApiProvider, authorizationApiProvider } from '@/common/df.authorization.api';
import {
  SEARCH_AUTH_BY_ID,
  GET_SOURCE_SYSTEM_CONFIG,
  GET_FULFILLMENT_OPTION_CONFIG,
  FETCH_SOURCE_SYSTEM_CLAIM,
  FETCH_SOURCE_SYSTEM_CONFIG,
  FETCH_FULFILLMENT_OPTION_CONFIGS,
} from '@/store/dynamicfulfillment/constants';
import ListItem from '@/components/common/ListItem.vue';
import EventBus from '../common/event.bus';
import DataTable from '@/components/common/DataTable.vue';
import { Action, State } from 'vuex-class';
import FulfillmentAuthorizationFilterLogs from '@/components/FulfillmentAuthorization/FulfillmentAuthorizationFilterLogs.vue';
import ProgramConfigModal from '@/components/configuration/ProgramConfigModal.vue';
import VueJSONEditor from '@/components/VueJSONEditor.vue';
import {jsonToText, removeCosmosSystemProperties} from '@/common/functions.helpers';
import ClaimedEquipment from '@/components/FulfillmentAuthorization/ClaimedEquipment.vue'
import { DateTime } from 'luxon';
import StatusHistoryComponent from '@/components/FulfillmentAuthorization/StatusHistoryComponent.vue';
import { copyToClipboard } from '@/common/utilities';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({
  components: {
    FulfillmentAuthorizationStatusLabel,
    FulfillmentTicketStatus,
    ListItem,
    PhoneNumber,
    ViewContent,
    FulfillmentAuthorizationTraces,
    FulfillmentAuthorizationRequestHistory,
    DataTable,
    Communications,
    FulfillmentAuthorizationFilterLogs,
    ProgramConfigModal: ProgramConfigModal,
    VueJSONEditor,
    SourceSystemClaimDataHistory,
    ClaimedEquipment,
    StatusHistoryComponent,
  },
})
export default class FulfillmentAuthorizationDetail extends Vue {
  @Prop() private authorizationId!: string;
  // tslint:disable-next-line:max-line-length
  @Action(SEARCH_AUTH_BY_ID, { namespace }) private searchAuthById: any;
  // tslint:disable-next-line:max-line-length
  @Action(FETCH_SOURCE_SYSTEM_CONFIG, { namespace }) private fetchSourceSystemConfig: (sourceSystem: string) => Promise<any>;
  // tslint:disable-next-line:max-line-length
  @Action(FETCH_SOURCE_SYSTEM_CLAIM, { namespace }) private fetchSourceSystemClaim: (sourceSystemIdentifier: SourceSystemIdentifier) => Promise<any>;
  @State('dynamicfulfillmentmodule') private profile: DynamicFulfillmentState;

  private authorization: FulfillmentAuthorization = null;
  private searchBy: string = 'fulfillmentAuthorization';
  private events: any[] = null;
  private sourceSystemClaimData: any = null;
  private statusFields: any[] = ['status', { key: 'reason', label: 'Status Reason' }, { key: 'modifiedDate', label: 'Status Date'}];
  private sourceSystemConfig: SourceSystemConfig = null;
  private filterLogsFields: any[] = ['offerSetId', 'programConfigVersion', 'createdOnUtc', 'showFilterTraces'];
  private jsonEditorText: any = jsonToText;
  private componentKey: number = 0;
  private isLoading: boolean = true;
  private removeCosmosSystemProperties : any = removeCosmosSystemProperties;
  private statusModal: any = { id: '', title: '', content: '' };
  
  public async mounted() {
    await this.refreshAuthorizationData(this.authorizationId, false);
  }

  @Watch('authorizationId', {immediate: false})
  public async refreshAuthorizationData(authorizationId: string, forceFetch: boolean) {
    this.authorization = await this.searchAuthById({ authorizationId: authorizationId, forceFetch: forceFetch });
    this.fetchSourceSystemConfigBySource(this.authorization);
    this.fetchEvents();
    this.getSourceSystemData();
    this.showHideOverlay();
  }

  private fetchEvents() {
    authorizationApiProvider.getEvents(this.authorizationId).then((events) => this.events = events);
  }

  private fetchSourceSystemConfigBySource(authorization: FulfillmentAuthorization) {
    let sourceSystem = '';
    switch (authorization.source) {
      case 1:
        sourceSystem = 'Eprism';
        break;
      case 2:
        sourceSystem = 'Elita';
        break;
      case 3:
        sourceSystem = 'Huxley';
        break;
      case 5:
        sourceSystem = 'Fixt';
        break;
      case 6:
        sourceSystem = 'Lori';
        break;
      case 7:
        sourceSystem = 'EIP';
        break;
      case 8:
        sourceSystem = 'Verizon';
        break;
      default:
        break;
    }
    this.fetchSourceSystemConfig(sourceSystem).then((config) => this.sourceSystemConfig = config);
  }

  private getSourceSystemData() {
    const sourceSystemIdentifier = this.sourceSystemIdentifier;
    this.fetchSourceSystemClaim(sourceSystemIdentifier).then((claim) => this.sourceSystemClaimData = claim);
  }

  private copyRawAuthEventJson(rawAuthEvent: any) {
    rawAuthEvent = removeCosmosSystemProperties(rawAuthEvent);
    copyToClipboard(JSON.stringify(rawAuthEvent, null, 2));
  }

  private resetStatusModal() {
    this.statusModal.title = '';
    this.statusModal.content = '';
  }

  private showStatusModal(statusItem: any, index: any, button: any) {
    this.statusModal.id = statusItem.id;
    this.statusModal.title = `Authorization Event`;
    this.statusModal.content = removeCosmosSystemProperties(statusItem);
    this.$root.$emit('bv::show::modal', this.statusModal.id, button);
  }

  private get sourceSystemIdentifier() {
    const sourceSystemIdentifier = {
        sourceSystem: this.authorization.source.toString(),
        claimNumber: (!this.authorization || !this.authorization.claimData || !this.authorization.claimData.claimSummary) ? '' : this.authorization.claimData.claimSummary.id,
      } as SourceSystemIdentifier;

    return sourceSystemIdentifier;
  }

  private get sourceSystem() {
    return this.sourceSystemConfig
      ? this.sourceSystemConfig.sourceSystem
      : '';
  }

  private get fulfillmentTickets() {
    if (!this.authorization || !this.authorization.fulfillmentTickets) {
      return [];
    }
    return this.authorization.fulfillmentTickets.sort((a, b) => a.createdOnUtc > b.createdOnUtc ? -1 : 1).map((t) => {
      const latestStatus = t.status.statusHistory.sort((a, b) => a.modifiedDate > b.modifiedDate ? -1 : 1)[0];
      return {
        t,
        id: t.id,
        currentStatus: latestStatus.status,
        statusReason: latestStatus.reason,
        statusDate: latestStatus.modifiedDate,
        fulfillmentOption: t.acceptedOfferInfo.offer.fulfillmentOption,
        deductibleAmount: t.acceptedOfferInfo.offer.deductibleAmount,
        acceptedOnUtc: t.fulfillmentTermsAcceptance.acceptedOnUtc,
      };
    });
  }

  private get claimStatus() {
    return this.profile.claimStatuses[this.authorization.claimData.claimSummary.status];
  }

  private claimDataToString() {
    return JSON.stringify(this.sourceSystemClaimData, null, 2);
  }

  private get fulfillmentTicketFields() {
    return [
      'id',
      {
        key: 'fulfillmentOption',
        label: 'Fulfillment Option',
        formatter: (value: any, key: any, item: any) => {
            return FulfillmentOption[value];
        },
    },
      { key: 'currentStatus', label: 'Status' },
      'statusReason',
      'statusDate',
      'actions',
    ];
  }

  private get eventFields() {
    return [
      'id',
      'eventType',
      'eventTime',
      'eventData',
      'actions',
    ];
  }

  private get sourceSystems() {
    return [
          { value: '', text: 'Select Source System' },
          { value: '1', text: 'ePrism' },
          { value: '2', text: 'Elita' },
          { value: '3', text: 'Huxley' },
          { value: '5', text: 'Fixt' },
          { value: '6', text: 'Lori' },
          { value: '8', text: 'Verizon' },
        ];
  }

  private get serviceSkus() {
    return (data: any[]) => {
      if (data === null || data === undefined ) {
        return '';
      } else {
        return data.join(', ');
      }
    };
  }

  private get damageTypes() {
    return (data: any[]) => {
      if (data === null || data === undefined ) {
        return '';
      } else {
        const types = data.map((d) => DamageType[d]);
        return types.join(', ');
      }
    };
  }

  private getUserTier(val: any) {
    return UserTier[val];
  }

  private showHideOverlay() {
    this.isLoading = !this.isLoading;
  }

  private refreshAuthorization() {    
    this.showHideOverlay();
    this.refreshAuthorizationData(this.authorizationId, true);
    this.componentKey += 1;
  }

  private formatDateTime(value: any) {
    return DateTime.fromISO(value).toFormat('yyyy-MM-dd HH:mm:ss');
  }

  private copyEventData(eventData: any) {
    copyToClipboard(JSON.stringify(eventData, null, 2));
  }
}
