
  import Vue from 'vue';
  import { Component, Prop, Watch } from 'vue-property-decorator';
  import OrderStatusLabel from '@/components/FulfillmentAuthorization/OrderStatusLabel.vue';
  import ConfigIndexComponent from '@/components/configuration/ConfigIndexComponent.vue';
  import { STATUS_MAPPING_BY_VENDOR_FULFILLMENTOPTION_FETCH, DELETE_STATUS_MAPPING } from '@/store/constants';
  import { Enums, FulfillmentOption, FulfillmentTicketStatus, RoutingAction, WorkflowDefinition, } from '@/store/dynamicfulfillment/types';
  import { DateTime } from 'luxon';
  import FulfillmentTicketStatusLabel from '@/components/FulfillmentAuthorization/FulfillmentTicketStatusLabel.vue';
  const namespace: string = 'dynamicfulfillmentmodule';
  
  @Component({
    components: { OrderStatusLabel, FulfillmentTicketStatusLabel, ConfigIndexComponent },
  })
  export default class OrderStatusMappingDetails extends Vue {
    fields: any[] = [
      {
        key: 'vendor',
        sortable: true,
        filterable: true,
      },
      {
        key: 'fulfillmentOption',
        sortable: true,
        filterByFormatted: true,
        formatter: (value: any, key: any, item: any) => {
          return FulfillmentOption[value];
        },
        filterable: true,
      },
      {
        key: 'serviceNetwork',
        sortable: true,
      },
      {
        key: 'sourceStateId',
        sortable: true,
        filterable: true,
      },
      {
        key: 'targetStatus',
        sortable: true,
        filterable: true,
        filterByFormatted: true,
        formatter: (value: any, key: any, item: any) => {
            return this.targetStatus(value);
        },
      },
      {
        key: 'targetReason',
        sortable: true,
        filterable: true,
      },
      {
        key: 'triggeringWorkflows',
        label: 'Workflow Name(s)',
        filterable: true,
        formatter: (value: any, key: any, item: any) => {
          return value.map((workflow: WorkflowDefinition) => workflow.name).join('<br />');
        },
      },
    ];
    exportFields: any[] = ['id', 'vendor', 'fulfillmentOption','serviceNetwork', 'sourceStateId', 'targetStatus', 'targetReason', 'action'];
    configExport: {
      id: string;
      vendor: string;
      fulfillmentOption: FulfillmentOption;
      serviceNetwork: string;
      sourceStateId: string;
      targetStatus: Enums.OrderStatus;
      targetReason: string;
      action: string;
      triggeringWorkflows: WorkflowDefinition[];
    }[] = null;
    filterOnFields: string[] = [];
  
    getFulfillmentOptionName() {
      return this.$route.query.fulfillmentOption;
    }

    get indexRoute() {
        switch(this.$route.query.statusType) {
            case 'vendorticketstatus':
                return {
                          name: 'VendorTickeStatusMapping-Details',
                          query: {
                            fulfillmentOption: this.$route.query.fulfillmentOption,
                            vendor: this.$route.query.vendor,
                            statusType: 'vendorticketstatus',
                          },
                        };
            case 'orderstatus':
                return {
                          name: 'OrderStatusMapping-Details',
                          query: {
                            fulfillmentOption: this.$route.query.fulfillmentOption,
                            vendor: this.$route.query.vendor,
                            statusType: 'orderstatus',
                          },
                        };
            default:
                return '';
        }
    }
  
    get pageTitle() {
      return `${this.getFulfillmentOptionName()} - ${this.$route.query.vendor} - ${this.title}`;
    }

    get title() {
        switch(this.$route.query.statusType) {
            case 'vendorticketstatus':
                return 'Vendor Ticket Status Mapping';
            case 'orderstatus':
                return 'Order Status Mapping';
        }
    }

    get targetStatus() {
        return (value: number) => {
            switch(this.$route.query.statusType) {
                case 'vendorticketstatus':
                    return FulfillmentTicketStatus[value];
                case 'orderstatus':
                    return Enums.OrderStatus[value];
            }
        }        
    }

    get route(){
        switch(this.$route.query.statusType) {
                case 'vendorticketstatus':
                    return 'VendorTicketStatusMapping';
                case 'orderstatus':
                    return 'OrderStatusMapping';
            }
    }
  
    get routeActions() {
        return [
            { name: RoutingAction.Edit, route: `${this.route}-Edit`, requiredPermission: `Configuration-${this.route}-Edit`, params: {statusType: this.$route.query.statusType} },
            { name: RoutingAction.Create, route: `${this.route}-Create`, query: {
                    fulfillmentOption: this.getFulfillmentOptionName(),
                    vendor: this.$route.query.vendor,
                },
                requiredPermission: `Configuration-${this.route}-Create`,
            },
            {
                name: RoutingAction.Fetch, action: STATUS_MAPPING_BY_VENDOR_FULFILLMENTOPTION_FETCH,
                params: {
                    fulfillmentOption: FulfillmentOption[this.$route.query.fulfillmentOption as any],
                    vendor: this.$route.query.vendor,
                    apiRoute: this.$route.query.statusType
                },
                requiredPermission: `Configuration-${this.route}-View`,
            },
            { name: RoutingAction.Remove, action: DELETE_STATUS_MAPPING, requiredPermission: `Configuration-${this.route}-Delete`, params: {apiRoute: this.$route.query.statusType} },
            {
                name: RoutingAction.Upload, route: `${this.route}-Upload`,
                query: {
                    fulfillmentOption: this.getFulfillmentOptionName(),
                    vendor: this.$route.query.vendor,
                },
                requiredPermission: `Configuration-${this.route}-Upload`,
                params: {
                    apiRoute: this.$route.query.statusType
                },
            },
            { name: RoutingAction.Download, requiredPermission: `Configuration-${this.route}-Download` },
        ];   
    }
  
    mapConfig(configs: any[]) {    
      this.configExport = configs.map((item: any) => ({
        id: item.id,
        vendor: item.vendor,
        fulfillmentOption: FulfillmentOption[item.fulfillmentOption],
        sourceStateId: item.sourceStateId,
        serviceNetwork: item.serviceNetwork,
        targetStatus: this.$route.query.statusType === 'vendorticketstatus'? FulfillmentTicketStatus[item.targetStatus] : Enums.OrderStatus[item.targetStatus],
        targetReason: item.targetReason,
        action: item.action,
        triggeringWorkflows: item.triggeringWorkflows,
      })) as [];
    }
  
    get exportFileName() {
      return `${this.getFulfillmentOptionName()}-${this.$route.query.vendor}-${this.route}s_${DateTime.now().toFormat('yyyyMMddHHmm')}.xlsx`;
    }
  
    remove(item: any) {
      var indexComponent = this.$refs.indexComponent as any;
      indexComponent.remove(item);
    }
  }
  