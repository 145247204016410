
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class ImplicitCallback extends Vue {
  public async beforeMount() {
    try {
      await this.$auth.handleAuthentication();
      this.$router.replace({
        path: this.$auth.getFromUri(),
      });
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.log(e);
      this.$router.push({ name: 'Home' } );
    }
  }
  // tslint:disable-next-line:no-empty
  public render() {}
}

